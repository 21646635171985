import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TopDiscussedTopics from '../TopDiscussedTopics';
import KeyPhrases from '../KeyPhrases';
import ChatIcon from '../ChatIcon';
import Footer from '../Footer';
import axios from 'axios';
import {API_HOST} from '../../settings';
import { PiFileAudioFill } from "react-icons/pi";
import { MdErrorOutline } from "react-icons/md";
import { MdOutlineFileDownloadDone } from "react-icons/md";
import Cookies from "universal-cookie";
import { LuLoader } from "react-icons/lu";
import "noty/lib/noty.css"; 
import "noty/lib/themes/relax.css";
import Pace from 'pace-js'; 
import { FaArrowUp } from "react-icons/fa";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { FaArrowDown } from "react-icons/fa";
import CircleIndicator from '../CircleIndicator.js';
import CanvasJSReact from '@canvasjs/react-charts';
import moment from "moment";
import $ from 'jquery';



const cookies = new Cookies();
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const Noty = require('noty');

class MainPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      baseDate: null, // To be fetched from the backend
      selectedFilter: "thisWeek", // Default filter
      currentRange: null, // Current period date range
      previousRange: null, // Previous period date range
      overallScore: 85,
      overallScore_diff: 8.2,
      ButtonFlag: 'inbound',
      totalConversations:40,
      totalConversations_diff:7.3,
      customers_interest:10,
      customers_interest_diff:15.3,
      pitch_ai:12,
      pitch_ai_diff:-2.2,
      overallTrend: 4.5,
      recentConversations:[],
      performingAgents:[],
      agentScores:[],
      overallScores:[],
      customerSatisfaction:[],  
      loading: true,
      error: null,
      showAll: false,
      call_type: 'inbound',
      top_discussed_topics: ['Gemini AI', 'Call IQ', 'Google Workspace'],
      key_phrases: ['Google Cloud Partner', 'Remember me', 'Shared the pricing'],
      topDiscussedTopics:[],
      requirement_products:[],
      options : {
          animationEnabled: true,
          title: {
            text: "Customer Satisfaction",
            fontSize: 14,
            fontWeight: 500,
            color:'#aaa5a5',
            padding: {
              top: 10,
              bottom: 10
            }
          },
          subtitles: [{
            text: "No Data Available",
            verticalAlign: "center",
            fontSize: 24,
            dockInsidePlotArea: true
          }],
          legend: {
                  fontSize: 12,
                  fontWeight: "normal",
                  markerMargin: 5,
                  markerSize: 10,
                  horizontalAlign: "center",
                  verticalAlign: "bottom",
                  itemStyle: {
                    marginBottom: 20,
                  }
              },
          data: [{
              type: "doughnut",
              showInLegend: true,
              indexLabel: "{name}: {y}",
              yValueFormatString: "#,###'%'",
              
              dataPoints: [
                { name: "Unsatisfied", y: 0 },
                { name: "Very Unsatisfied", y: 0 },
                { name: "Very Satisfied", y: 0 },
                { name: "Satisfied", y: 0 },
                { name: "Neutral", y: 0 }
                ]
              }]
        }
      };
}

notify = (msg, message_type) => {

    var type = message_type;
    var position = "topCenter";
    new Noty({
      theme: 'relax',
      text: msg,
      type: type,
      timeout: 3000,
      layout: position,
      animation: {
        open: 'animated bounceInLeft',
        close: 'animated bounceOutUp'
      }
    }).show();
  };


  getStrokeColor(value) {
    if (value <= 25) {
      return 'red';
    } else if (value <= 80) {
      return '#F08000';
    } else if (value <= 100) {
      return '#009900';
    }
  }

  toggleShowAll = () => {
    this.setState((prevState) => ({
      showAll: !prevState.showAll,
    }));
  };

  fetchOverallscores(type, currentRange, previousRange, call_type) {

    if(type=='GET'){
      var clear_local_storage = false
     const auth_token = cookies.get("calliq_token")
     const csrf_token = cookies.get("csrftoken")
     axios.defaults.headers.get['Authorization'] =auth_token
     axios.get(`${API_HOST}/home/scores/?call_type=${call_type}`, {
       headers: {
         'Content-Type': 'multipart/form-data',
         'Authorization': auth_token
       }
     }).then((response) => {
        if (response.status!=200) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = response.data
        this.setState({
          overallScores: data,
          
        });
        
      })
      .catch(error => {
        
      if(error.response.status == 401 ){
        // alert("Token expired")
        clear_local_storage = true
        this.notify("Token expired","error")

      }
      else {
          this.notify("Something went wrong. Try again.",'error')
          clear_local_storage=true
      }
      setTimeout(function(){
            if(clear_local_storage){
                cookies.remove('calliq_token',{path:'/'})
                localStorage.clear()
                window.location.href = "/login/"
                
            }                
        },1000)


      // console.error('There was an error processing the audio:', error);
    });

    }
    else{
      const payload = {
        start_date_current: currentRange?.start,
        end_date_current: currentRange?.end,
        start_date_previous: previousRange?.start,
        end_date_previous: previousRange?.end,
      };
    var clear_local_storage = false
     const formData = new FormData();
     const auth_token = cookies.get("calliq_token")
     formData.append('current_date', this.state.baseDate)
     formData.append('date_ranges',JSON.stringify(payload) )
     formData.append('call_type', this.state.call_type)
     axios.defaults.headers.get['Authorization'] =auth_token
     axios.post(`${API_HOST}/home/scores/?call_type=${call_type}`, formData, {
       headers: {
         'Content-Type': 'multipart/form-data',
         'Authorization': auth_token
       }
     }).then((response) => {
        if (response.status!=200) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = response.data
        this.setState({
          overallScores: data,
          
        });
        
      })
      .catch(error => {
        
      if(error.response.status == 401 ){
        // alert("Token expired")
        clear_local_storage = true
        this.notify("Token expired","error")

      }
      else {
          this.notify("Something went wrong. Try again.",'error')
          clear_local_storage=true
      }
      setTimeout(function(){
            if(clear_local_storage){
                cookies.remove('calliq_token',{path:'/'})
                localStorage.clear()
                window.location.href = "/login/"
                
            }                
        },1000)


      // console.error('There was an error processing the audio:', error);
    });
    }
    
  };


  fetchConversations(type, currentRange, previousRange,call_type) {
    if(type=="GET"){
      const auth_token = cookies.get("calliq_token")
      var clear_local_storage = false
      this.setState({ loader_g: true, conversation_details: "", message:"", file_name:""});
      const csrf_token = cookies.get("csrftoken")
      axios.defaults.headers.get['Authorization'] =auth_token
      axios.get(`${API_HOST}/home/recent/conversations?call_type=${call_type}`, {
       headers: {
         'Content-Type': 'multipart/form-data',
         'Authorization': auth_token
       }
       })
      .then(response => {
        

          if(response.status == 200 ){
            const data = response.data
            this.setState({
              recentConversations: response.data.recent_conversations, 
            });
            
          }
      })
      .catch((error) => {
        
              if(error.response.status == 401 ){
        // alert("Token expired")
        clear_local_storage = true
        this.notify("Token expired","error")

      }
      else {
          this.notify("Something went wrong. Try again.",'error')
          clear_local_storage=true
      }
      setTimeout(function(){
            if(clear_local_storage){
                cookies.remove('calliq_token',{path:'/'})
                localStorage.clear()
                window.location.href = "/login/"
                
            }                
        },1000)
       // $('#loader-wrapper').fadeOut('slow');
    })
  }
    else{
      const payload = {
        start_date_current: currentRange?.start,
        end_date_current: currentRange?.end,
        start_date_previous: previousRange?.start,
        end_date_previous: previousRange?.end,
      };
      
     const formData = new FormData();
     const auth_token = cookies.get("calliq_token")
     formData.append('current_date', this.state.baseDate)
     formData.append('date_ranges',JSON.stringify(payload) )
     formData.append('call_type', this.state.call_type)
     // axios.defaults.headers.post['Authorization'] =auth_token
     axios.post(`${API_HOST}/home/recent/conversations/?call_type=${call_type}`, formData, {
       headers: {
         'Content-Type': 'multipart/form-data',
         'Authorization': auth_token
       }
     }).then((response) => {
        if (response.status!=200) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = response.data
        
        this.setState({
          recentConversations: data.recent_conversations,
          
        });
        // $('#loader-wrapper').fadeOut('slow')
        return response.json();
      })
      .catch((error) => {
        this.setState({ error: error.message,  });
      });
     
    }
    
  }


  fetchperformingAgents(type, currentRange, previousRange,call_type) {
    if(type=="GET"){
      const auth_token = cookies.get("calliq_token")
      var clear_local_storage = false
      axios.defaults.headers.get['Authorization'] =auth_token
      axios.get(`${API_HOST}/home/top/performing/agents?call_type=${call_type}`, {
       headers: {
         'Content-Type': 'multipart/form-data',
         'Authorization': auth_token
       }
       })
      .then(response => {

          if(response.status == 200 ){
            const data = response.data
            this.setState({
              performingAgents: data.top_performing_agents,
            });
          }
      })
      .catch((error) => {
        
              if(error.response.status == 401 ){
        // alert("Token expired")
        clear_local_storage = true
        this.notify("Token expired","error")

      }
      else {
          this.notify("Something went wrong. Try again.",'error')
          clear_local_storage=true
      }
      setTimeout(function(){
            if(clear_local_storage){
                cookies.remove('calliq_token',{path:'/'})
                localStorage.clear()
                window.location.href = "/login/"
                
            }                
        },1000)
       // $('#loader-wrapper').fadeOut('slow');
      })
    }
    else{
      const payload = {
        start_date_current: currentRange?.start,
        end_date_current: currentRange?.end,
        start_date_previous: previousRange?.start,
        end_date_previous: previousRange?.end,
      };
      
     const formData = new FormData();
     const auth_token = cookies.get("calliq_token")
     formData.append('current_date', this.state.baseDate)
     formData.append('date_ranges',JSON.stringify(payload) )
     formData.append('call_type', this.state.call_type)
     axios.post(`${API_HOST}/home/top/performing/agents/?call_type=${call_type}`, formData, {
       headers: {
         'Content-Type': 'multipart/form-data',
         'Authorization': auth_token
       }
     }).then((response) => {
        if (response.status!=200) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = response.data
        this.setState({
            performingAgents: data.top_performing_agents,
            
            });
        return response.json();
      })
      .catch((error) => {
        this.setState({ error: error.message,  });
      });
     

    }
    
  };

  fetchAgentScores(type, currentRange, previousRange,call_type) {
    if(type=="GET"){

        const auth_token = cookies.get("calliq_token")
        var clear_local_storage = false
        axios.defaults.headers.get['Authorization'] =auth_token
        axios.get(`${API_HOST}/home/agent/scores?call_type=${call_type}`, {
       headers: {
         'Content-Type': 'multipart/form-data',
         'Authorization': auth_token
       }
       })
         .then(response => {

         if(response.status == 200 ){
           const data = response.data
           this.setState({
             agentScores: data.agent_scores,
           });
         }
       })
       .catch((error) => {
         
               if(error.response.status == 401 ){
         // alert("Token expired")
         clear_local_storage = true
         this.notify("Token expired","error")

       }
       else {
           this.notify("Something went wrong. Try again.",'error')
           clear_local_storage=true
       }
       setTimeout(function(){
             if(clear_local_storage){
                 cookies.remove('calliq_token',{path:'/'})
                 localStorage.clear()
                 window.location.href = "/login/"
                 
             }                
         },1000)
        // $('#loader-wrapper').fadeOut('slow');
       })
     }
    else{

      const payload = {
        start_date_current: currentRange?.start,
        end_date_current: currentRange?.end,
        start_date_previous: previousRange?.start,
        end_date_previous: previousRange?.end,
      };
      
     const formData = new FormData();
     const auth_token = cookies.get("calliq_token")
     formData.append('current_date', this.state.baseDate)
     formData.append('date_ranges',JSON.stringify(payload) )
     formData.append('call_type', this.state.call_type)
     axios.post(`${API_HOST}/home/agent/scores/?call_type=${call_type}`, formData, {
       headers: {
         'Content-Type': 'multipart/form-data',
         'Authorization': auth_token
       }
     }).then((response) => {
        if (response.status!=200) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = response.data
        this.setState({
              agentScores: data.agent_scores,
              
            });
        return response.json();
      })
      .catch((error) => {
        this.setState({ error: error.message,  });
      });
     
      }

    }

  fetchTrendingTopics(type, currentRange, previousRange,call_type) {
    if(type=="GET"){
       const apiURL = `${API_HOST}/home/trending/topics?call_type=${call_type}`;// Replace with your actual API URL
      const auth_token = cookies.get("calliq_token")
      var clear_local_storage = false
      axios.defaults.headers.get['Authorization'] =auth_token
      axios.get(`${API_HOST}/home/trending/topics?call_type=${call_type}`, {
       headers: {
         'Content-Type': 'multipart/form-data',
         'Authorization': auth_token
       }
       })
      .then(response => {

          if(response.status == 200 ){
            const data = response.data
            this.setState({
            topDiscussedTopics: data.trending_topics,
            baseDate: data.current_date,
            
          });
          const { currentRange, previousRange } = this.calculateDateRanges(this.state.selectedFilter, this.state.baseDate);
          this.setState(
            {
              currentRange,
              previousRange,
            })
          }
      })
      .catch((error) => {
            
              if(error.response.status == 401 ){
        // alert("Token expired")
        clear_local_storage = true
        this.notify("Token expired","error")

      }
      else {
          this.notify("Something went wrong. Try again.",'error')
          clear_local_storage=true
      }
      setTimeout(function(){
            if(clear_local_storage){
                cookies.remove('calliq_token',{path:'/'})
                localStorage.clear()
                window.location.href = "/login/"
                
            }                
        },1000)
       // $('#loader-wrapper').fadeOut('slow');
      })
    }
    else{
      const payload = {
        start_date_current: currentRange?.start,
        end_date_current: currentRange?.end,
        start_date_previous: previousRange?.start,
        end_date_previous: previousRange?.end,
      };
      
     const formData = new FormData();
     const auth_token = cookies.get("calliq_token")
     formData.append('current_date', this.state.baseDate)
     formData.append('date_ranges',JSON.stringify(payload) )
     formData.append('call_type', this.state.call_type)
      axios.post(`${API_HOST}/home/trending/topics/?call_type=${call_type}`, formData, {
       headers: {
         'Content-Type': 'multipart/form-data',
         'Authorization': auth_token
       }
     })
     .then((response) => {
        if (response.status!=200) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = response.data
        this.setState({
          topDiscussedTopics: data.trending_topics,
          
        });
        return response.json();
      })
      .catch((error) => {
        this.setState({ error: error.message,  });
      });

    }
   
  };


  fetchRequirementProducts(type, currentRange, previousRange, call_type) {
    if(call_type=='inbound'){
      

      if(type=="GET"){
         // const apiURL = `${API_HOST}/home/products/requirement?call_type=${call_type}`;// Replace with your actual API URL
      
        const auth_token = cookies.get("calliq_token")
        var clear_local_storage = false
        axios.defaults.headers.get['Authorization'] =auth_token
        axios.get(`${API_HOST}/home/products/requirement?call_type=${call_type}`, {
       headers: {
         'Content-Type': 'multipart/form-data',
         'Authorization': auth_token
       }
       })
        .then(response => {
          console.log(response)
          if(response.status == 200 ){
            const data = response.data
            this.setState({
            requirement_products: data.requirement_products,
            baseDate: data.current_date,
            
          });
          const { currentRange, previousRange } = this.calculateDateRanges(this.state.selectedFilter, this.state.baseDate);
          this.setState(
            {
              currentRange,
              previousRange,
            })
          }
      })
      .catch((error) => {
        
          
              if(error.response.status == 401 ){
        // alert("Token expired")
        clear_local_storage = true
        this.notify("Token expired","error")

      }
      else {
          this.notify("Something went wrong. Try again.",'error')
          clear_local_storage=true
      }
      setTimeout(function(){
            if(clear_local_storage){
                cookies.remove('calliq_token',{path:'/'})
                localStorage.clear()
                window.location.href = "/login/"
                
            }                
        },1000)
       // $('#loader-wrapper').fadeOut('slow');
      })

      }
      else{
        const payload = {
          start_date_current: currentRange?.start,
          end_date_current: currentRange?.end,
          start_date_previous: previousRange?.start,
          end_date_previous: previousRange?.end,
        };
        
       const formData = new FormData();
       const auth_token = cookies.get("calliq_token")
       formData.append('current_date', this.state.baseDate)
       formData.append('date_ranges',JSON.stringify(payload) )
       formData.append('call_type', this.state.call_type)
        axios.post(`${API_HOST}/home/products/requirement/?call_type=${call_type}`, formData, {
         headers: {
           'Content-Type': 'multipart/form-data',
           'Authorization':auth_token
         }
       })
       .then((response) => {
          if (response.status!=200) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          const data = response.data
          this.setState({
            requirement_products: data.requirement_products,
            
          });
          return response.json();
        })
        .catch((error) => {
          this.setState({ error: error.message,  });
        });

      }
    }
    else{
      this.setState({requirement_products:[]})
    }
 
};



  fetchCustomerSatisfaction(type, currentRange, previousRange, call_type) {
    if(type=='GET'){
      
      // const apiURL = `${API_HOST}/home/customers/satisfaction/scores?call_type=${call_type}`;// Replace with your actual API URL

        const auth_token = cookies.get("calliq_token")
        var clear_local_storage = false
        axios.defaults.headers.get['Authorization'] =auth_token
        axios.get(`${API_HOST}/home/customers/satisfaction/scores?call_type=${call_type}`, {
       headers: {
         'Content-Type': 'multipart/form-data',
         'Authorization': auth_token
       }
       })
        .then(response => {

            if(response.status == 200 ){
              const data = response.data
              if(data.check_flag){
                this.setState({
                  customerSatisfaction: data.customer_satisfaction,
                  
                  options: {
                    animationEnabled: true,
                    title: {
                      text: "Customer Satisfaction",
                      fontSize: 14,
                      fontWeight: 500,
                      color: '#aaa5a5',
                    },
                    subtitles: [{
                      text: `${(data.customer_satisfaction.satisfied +  data.customer_satisfaction.very_satisfied).toFixed(2)}% Positive`,
                      verticalAlign: "center",
                      fontSize: 24,
                      dockInsidePlotArea: true,
                    }],
                    legend: {
                        fontSize: 12,
                        fontWeight: "normal",
                        markerMargin: 5,
                        markerSize: 10,
                        horizontalAlign: "center",
                        verticalAlign: "bottom",
                    },

                    data: [{
                      type: "doughnut",
                      showInLegend: true,
                      indexLabel: "{name}: {y}",
                      yValueFormatString: "#,###'%'",
                      dataPoints: [
                        { name: "Unsatisfied", y: data.customer_satisfaction.unsatisfied },
                        { name: "Very Unsatisfied", y: data.customer_satisfaction.very_unsatisfied },
                        { name: "Very Satisfied", y: data.customer_satisfaction.very_satisfied },
                        { name: "Satisfied", y: data.customer_satisfaction.satisfied },
                        { name: "Neutral", y: data.customer_satisfaction.neutral },
                      ],
                    }],
                  }
                });
              }
               else{
                console.log('check',this.state.options.data[0].dataPoints)
                const dataPoints = this.state.options.data[0].dataPoints;
                   
                // Check if all values are zero and update options accordingly
                const allZeroValues = dataPoints.every(point => point.y === 0);
                
                if (allZeroValues) {
                 this.setState({
                   options: {
                     title: {
                      text: "Customer Satisfaction",
                      fontSize: 14,
                      fontWeight: 500,
                      color: '#aaa5a5',
                     },
                     subtitles: [{
                       text: `No Data`,
                       verticalAlign: "center",
                       fontSize: 24,
                       dockInsidePlotArea: true,
                     }],
                     data: [{
                       type: "doughnut",
                       showInLegend: true,
                       
                       yValueFormatString: "#,###'%'",
                       dataPoints: [
                         { name: "No Data", y: 1 }
                       ]
                     }]
                   }
                 });
                }
              }
            }
        })
        .catch((error) => {
          
                if(error.response.status == 401 ){
          // alert("Token expired")
          clear_local_storage = true
          this.notify("Token expired","error")

        }
        else {
            this.notify("Something went wrong. Try again.",'error')
            clear_local_storage=true
        }
        setTimeout(function(){
              if(clear_local_storage){
                  cookies.remove('calliq_token',{path:'/'})
                  localStorage.clear()
                  window.location.href = "/login/"
                  
              }                
          },1000)
         // $('#loader-wrapper').fadeOut('slow');
        })
      }
    else{
      const payload = {
        start_date_current: currentRange?.start,
        end_date_current: currentRange?.end,
        start_date_previous: previousRange?.start,
        end_date_previous: previousRange?.end,
      };
      
     const formData = new FormData();
     const auth_token = cookies.get("calliq_token")
     formData.append('current_date', this.state.baseDate)
     formData.append('date_ranges',JSON.stringify(payload) )
     formData.append('call_type', this.state.call_type)
      axios.post(`${API_HOST}/home/customers/satisfaction/scores/?call_type=${call_type}`, formData, {
       headers: {
         'Content-Type': 'multipart/form-data',
         'Authorization':auth_token
       }
     }).then((response) => {
      
        if (response.status!=200) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = response.data
        if(data.check_flag){
          this.setState({
            customerSatisfaction: data.customer_satisfaction,
            
            options: {
              animationEnabled: true,
              title: {
                text: "Customer Satisfaction",
                fontSize: 14,
                fontWeight: 500,
                color: '#aaa5a5',
              },
              subtitles: [{
                text: `${(data.customer_satisfaction.satisfied +  data.customer_satisfaction.very_satisfied).toFixed(2)}% Positive`,
                verticalAlign: "center",
                fontSize: 24,
                dockInsidePlotArea: true,
              }],
              legend: {
                  fontSize: 12,
                  fontWeight: "normal",
                  markerMargin: 5,
                  markerSize: 10,
                  horizontalAlign: "center",
                  verticalAlign: "bottom",
              },

              data: [{
                type: "doughnut",
                showInLegend: true,
                indexLabel: "{name}: {y}",
                yValueFormatString: "#,###'%'",
                dataPoints: [
                  { name: "Unsatisfied", y: data.customer_satisfaction.unsatisfied },
                  { name: "Very Unsatisfied", y: data.customer_satisfaction.very_unsatisfied },
                  { name: "Very Satisfied", y: data.customer_satisfaction.very_satisfied },
                  { name: "Satisfied", y: data.customer_satisfaction.satisfied },
                  { name: "Neutral", y: data.customer_satisfaction.neutral },
                ],
              }],
            }
          });
        }
         else{
          console.log('check',this.state.options.data[0].dataPoints)
          const dataPoints = this.state.options.data[0].dataPoints;
             
          // Check if all values are zero and update options accordingly
          const allZeroValues = dataPoints.every(point => point.y === 0);
          
          if (allZeroValues) {
           this.setState({
             options: {
               title: {
                text: "Customer Satisfaction",
                fontSize: 14,
                fontWeight: 500,
                color: '#aaa5a5',
               },
               subtitles: [{
                 text: `No Data`,
                 verticalAlign: "center",
                 fontSize: 24,
                 dockInsidePlotArea: true,
               }],
               data: [{
                 type: "doughnut",
                 showInLegend: true,
                 
                 yValueFormatString: "#,###'%'",
                 dataPoints: [
                   { name: "No Data", y: 1 }
                 ]
               }]
             }
           });
          }
        }
        return response.json();
      })
      .catch((error) => {
        this.setState({ error: error.message,  });
      });
    }
    
  };


  // Call fetch method when the component is mounted
  componentDidMount() {
     $('#loader-wrapper').fadeIn('slow')
     const queryParams = new URLSearchParams(window.location.search);

     const param1 = queryParams.get('call-type');
    const auth_token = cookies.get("calliq_token")
    this.setState({call_type: param1, ButtonFlag: param1});
    if(!param1){
      window.location.href = '/?call-type=inbound'
    }
    if(auth_token){
      this.fetchConversations('GET',this.state.currentRange, this.state.previousRange,param1);
      this.fetchperformingAgents('GET',this.state.currentRange, this.state.previousRange,param1);
      this.fetchAgentScores('GET',this.state.currentRange, this.state.previousRange,param1);
      this.fetchOverallscores('GET',this.state.currentRange, this.state.previousRange,param1);
      this.fetchCustomerSatisfaction('GET',this.state.currentRange, this.state.previousRange,param1);
      this.fetchTrendingTopics('GET',this.state.currentRange, this.state.previousRange,param1);
      this.fetchRequirementProducts('GET',this.state.currentRange, this.state.previousRange,param1)
            
    }
    else{
     window.location.href = '/login'
    }
    
    setTimeout( () => {
      this.setState({loading: false});
       $('#loader-wrapper').fadeOut('slow')
      }, 4000)
    
  }

  calculateDateRanges(filter, baseDate) {
    const baseMoment = moment(this.state.baseDate, "YYYY-MM-DD");
    const calculateRange = (momentObj, units, count) => ({
         start: momentObj.clone().add(count, units).startOf(units).format("YYYY-MM-DD"),
         end: momentObj.clone().add(count, units).endOf(units).format("YYYY-MM-DD"),
       });
    switch (filter) {
      case "thisWeek":
        return {
          currentRange: calculateRange(baseMoment, "week", 0),
          previousRange: calculateRange(baseMoment, "week", -1),
        };
      case "lastWeek":
        return {
          currentRange: calculateRange(baseMoment, "week", -1),
          previousRange: calculateRange(baseMoment, "week", -2),
        };
      case "thisMonth":
        return {
          currentRange: calculateRange(baseMoment, "month", 0),
          previousRange: calculateRange(baseMoment, "month", -1),
        };
      case "lastMonth":
        return {
          currentRange: calculateRange(baseMoment, "month", -1),
          previousRange: calculateRange(baseMoment, "month", -2),
        };
      case "thisQuarter":
        return {
          currentRange: calculateRange(baseMoment, "quarter", 0),
          previousRange: calculateRange(baseMoment, "quarter", -1),
        };
      case "lastQuarter":
        return {
          currentRange: calculateRange(baseMoment, "quarter", -1),
          previousRange: calculateRange(baseMoment, "quarter", -2),
        };
      case "thisYear":
        return {
          currentRange: calculateRange(baseMoment, "year", 0),
          previousRange: calculateRange(baseMoment, "year", -1),
        };
      case "lastYear":
        return {
          currentRange: calculateRange(baseMoment, "year", -1),
          previousRange: calculateRange(baseMoment, "year", -2),
        };
      default:
        return { currentRange: null, previousRange: null };
    }
  }


  handleFilterChange = (event) => {
    const { baseDate } = this.state;
    const selectedFilter = event.target.value;
    this.updateDateRanges(selectedFilter, baseDate);
  };

    updateDateRanges = (filter, baseDate) => {
    const { currentRange, previousRange } = this.calculateDateRanges(filter, baseDate);
    console.log(currentRange, previousRange)
    this.setState(
      {
        baseDate,
        selectedFilter: filter,
        currentRange,
        previousRange,
      },
      () => {
        // Ensure state updates are completed before sending
        $('#loader-wrapper').fadeIn('slow')
        const queryParams = new URLSearchParams(window.location.search);

        const param1 = queryParams.get('call-type');
        
        this.setState({call_type: param1});
        this.fetchTrendingTopics('POST',this.state.currentRange, this.state.previousRange,param1);
        this.fetchCustomerSatisfaction('POST',this.state.currentRange, this.state.previousRange,param1);
        this.fetchAgentScores('POST', this.state.currentRange, this.state.previousRange,param1);
        this.fetchConversations('POST',this.state.currentRange, this.state.previousRange,param1);
        this.fetchperformingAgents('POST',this.state.currentRange, this.state.previousRange,param1);
        this.fetchOverallscores('POST',this.state.currentRange, this.state.previousRange,param1);
        this.fetchRequirementProducts('POST',this.state.currentRange, this.state.previousRange,param1)
        setTimeout( () => {
       $('#loader-wrapper').fadeOut('slow')
      }, 4000)
      }
    );
  };


  changeFlag = () => {
      if(this.state.ButtonFlag == 'inbound'){

        this.setState({ ButtonFlag: 'outbound', call_type: 'outbound' });
        window.location.href = "/?call-type=outbound"
      }
      else{
        this.setState({ ButtonFlag: 'inbound', call_type: 'inbound' });
        window.location.href = "/?call-type=inbound"
      }
      console.log(this.state.ButtonFlag)
    };


  render() {
    const { overallScore, overallTrend, data, ButtonFlag } = this.state;
     const value = this.state.overall_score_of_agent;
    const strokeColor = this.getStrokeColor(value);
    const performing_agents = this.state.performing_agents
    const recent_conversations = this.state.recent_conversations
    const agent_score_graph = this.state.agent_score_graph;
    const {loading, error,performingAgents, agentScores, overallScores, topDiscussedTopics, recentConversations, showAll } = this.state;

    const { selectedFilter, dateRange, baseDate, currentRange, previousRange, requirement_products } = this.state;
    
    return (
      <>
        <div id="loader-wrapper">
          <div id="loader"></div>
          <div className="loader-section section-left"></div>
          <div className="loader-section section-right"></div>
        </div>
        <div className="d-flex" style={{ justifyContent: 'space-between' }}>
          <div>
            <label htmlFor="dateFilter"  style={{ fontSize:'16px'}}>Dashboard for </label>
            <select
              id="dateFilter"
              className="dataFilters"
              value={selectedFilter}
              onChange={this.handleFilterChange}>
              <option value="thisWeek">this Week</option>
              <option value="lastWeek">last Week</option>
              <option value="thisMonth">this Month</option>
              <option value="lastMonth">last Month</option>
              <option value="thisQuarter">this Quarter</option>
              <option value="lastQuarter">last Quarter</option>
              <option value="thisYear">this Year</option>
              <option value="lastYear">last Year</option>
            </select>
          </div>
          <div className="d-flex">
            <button
              className="InboundButton"
              onClick={this.changeFlag}
              style={{ background: ButtonFlag === 'inbound' ? 'green': 'gray' }}
          >
              Inbound
          </button>
          <button
              onClick={this.changeFlag}
              className="OutboundButton"
              
              style={{ background: ButtonFlag === 'outbound' ? 'green': 'gray' }}
             
          >
              Outbound
          </button>

          </div>
        </div>
        <div className="container-fluid" >
          <Row className="row-first">
            <Col lg="6" >
              <div className="row scores-all">
                <Col lg="4" className="score_header">
                  <p className="header-class">Agent Score</p>
                  <p className="value-class">{overallScores.agent_score?.score || '0'}</p>
                  <p className="diff-class">
                    <span style={{ color: overallScores.agent_score?.flag === "Positive" ? 'green' : 'red' }}>
                      {overallScores.agent_score?.flag === "Positive" ? (
                        <FaArrowUp className="arrow-class" />
                      ) : (
                        <FaArrowDown className="arrow-class" />
                      )}
                      {overallScores.agent_score?.score_diff ?? '0'}%
                    </span> from last week
                  </p>
                </Col>
                <Col lg="4" className="score_header">
                  <p className="header-class">Total Conversations</p>
                  <p className="value-class">{overallScores.total_conversations?.count || '0'}</p>
                  <p className="diff-class">
                    <span style={{ color: overallScores.total_conversations?.flag === "Positive" ? 'green' : 'red' }}>
                      {overallScores.total_conversations?.flag === "Positive" ? (
                        <FaArrowUp className="arrow-class" />
                      ) : (
                        <FaArrowDown className="arrow-class" />
                      )}
                      {overallScores.total_conversations?.count_diff ?? '0'}%
                    </span> from last week
                  </p>
                </Col>
                <Col lg="4" className="score_header">
                  <p className="header-class">Customers Interest</p>
                  <p className="value-class">{overallScores.customer_interest?.count || '0'}</p>
                  <p className="diff-class">
                    <span style={{ color:overallScores.customer_interest?.flag === "Positive" ? 'green' : 'red' }}>
                      {overallScores.customer_interest?.flag === "Positive" ? (
                        <FaArrowUp className="arrow-class" />
                      ) : (
                        <FaArrowDown className="arrow-class" />
                      )}
                      {overallScores.customer_interest?.count_diff ?? '0'}%
                    </span> from last week
                  </p>
                </Col>
              </div>
            </Col>
            <Col lg="6" className="agent-col">
              <div className="white-bg">
                <div className="agent-trend">
                <p className="header-class">Agent Performance Trend</p>
                <ResponsiveContainer width="100%" height={125}>
                  <LineChart
                    data={agentScores}
                    margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                  >
                    {/* Add Cartesian Grid */}
                    <CartesianGrid strokeDasharray="3 3" />

                    {/* Add X-axis for dates */}
                    <XAxis
                      dataKey="date"
                      tickFormatter={(tick) => new Date(tick).toLocaleDateString("en-US", {
                        month: "short",
                        day: "numeric",
                      })}
                      label={{
                        value: "Date",
                        position: "insideBottom",
                        offset: -5,
                      }}
                    />

                    {/* Add Y-axis for scores */}
                    <YAxis
                      domain={[0, 100]}
                      label={{
                        value: "Score",
                        angle: -90,
                        position: "insideLeft",
                      }}
                    />

                    {/* Add Tooltip */}
                    <Tooltip
                      formatter={(value) => `Score: ${value}`}
                      labelFormatter={(label) => `Date: ${label}`}
                    />

                    {/* Add Line with Dots */}
                    <Line
                      type="monotone"
                      dataKey="score"
                      stroke="#A020F0"
                      strokeWidth={2}
                      dot={{ r: 5 }}
                    />
                  </LineChart>
                </ResponsiveContainer>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="4" style={{ paddingBottom: '10px' }}>
              <CanvasJSChart options = {this.state.options} style={{padding:'14px 0px', position:'relative'}}
                containerProps={{
                    style: {
                      height: '100%',
                      width: '100%',
                      position:'relative'}
              }}/>
            </Col>
            <Col lg="8" className="secound-block">
              <Row style={{ height: '-webkit-fill-available' }}>
                <Col lg="4" style={{ padding: '10px', paddingTop: '0px' }}>
                  <div className="top-requirements-class">
                    <p className='header-class'>Top Requirements</p>
                    { Object.keys(requirement_products)==false ? 
                    <p className="no-data">No data</p>:
                    <table className="table">
                      <tbody>
                        {requirement_products.map((item, index) => (
                          <tr key={index}>
                            <td>{item.product}</td>
                            <td className="progress-container">
                              <span className="progress-max">{item.count}</span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table> 
                    }
                  </div>
                </Col>
                <Col lg="4" style={{ padding: '10px', paddingTop: '0px' }}>
                  <div className="top-requirements-class">
                    <div className="d-flex justify-content-between">
                      <p className='header-class'>Trending topics</p>
                      <button
                        className="toggle-button"
                        onClick={this.toggleShowAll}
                        style={{
                          background: "none",
                          border: "none",
                          cursor: "pointer",
                          fontSize: "16px",
                        }}
                      >
                        {showAll ? "▼" : "◀"} {/* Conditional arrow */}
                      </button>
                    </div>
                    <ul
                      className="top-lists"
                      style={{
                        maxHeight: showAll ? "130px" : "auto", // Set height only if showing all topics
                        overflowY: showAll ? "scroll" : "hidden", // Enable scroll when showing all
                      }}>
                        {topDiscussedTopics.length ? 
                          (showAll ? topDiscussedTopics : topDiscussedTopics.slice(0, 3)).map(
                          (each_topic, index) => (
                            <li className="topics-list" key={index}>
                              <span>{each_topic.topic}</span>
                            </li>
                          )
                        ):
                        <li className="topics-list"><span className="no-data">No data</span></li>}
                      </ul>  
                    </div>
                </Col>
                <Col lg="4" style={{ padding: '10px', paddingTop: '0px' }}>
                  <div className="top-requirements-class" >
                    <p className='header-class'> Top Performing Agents</p>
                    { performingAgents.length ? 

                      <table className="table">
                        <thead>
                          <tr>
                              <th className="title-header">Agent Name</th>
                              <th className="title-header text-center">Score</th>  
                          </tr>
                        </thead>
                        <tbody>
                          {performingAgents.map((item, index) => (
                            <tr key={index}>
                              <td style={{ textWrap: 'nowrap' }}>{item.name}</td>
                              <td className="progress-container">
                                <progress value={item.score} max="100" className="progress-bar" style={{'cursor':'pointer'}} title={item.score}></progress>
                                <span className="progress-max">{item.score}%</span>
                              </td>
                            </tr>
                          ))}
                        </tbody>

                      </table>:
                        <p className="no-data">No data</p>
                    }
                  </div>
                </Col>
              </Row>
              <Row style={{ height: '-webkit-fill-available', paddingLeft:'10px', paddingRight:'10px' }}>
                <div className="recent-conversations-class white-bg" >
                  <p className="header-class">Recent Conversations</p>
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="title-header">Agent</th>
                        <th className="title-header">Call Date</th>
                        <th className="title-header">Primary Topic</th>
                        <th className="title-header">Score</th>
                        <th className="title-header">Pitch Score</th>
                        <th className="title-header">Sentiment</th>
                      </tr>
                    </thead>
                    <tbody>
                      {recentConversations.length === 0 ? 
                        <tr>
                         <td></td>
                         <td></td>
                         <td></td>
                         <td>No data</td>
                         <td></td>
                         <td></td>
                        </tr>:
                        recentConversations.map((conversation, index) => (
                          <tr key={index}>
                            <td>{conversation.agent_name || 'NA'}</td>
                            <td>{conversation.call_date || 'NA' }</td>
                            <td>{conversation.primary_topic || 'NA'}</td>
                            <td>{conversation.score || 'NA'}</td>
                            <td>{conversation.pitch_score || 'NA'}</td>
                            <td>{conversation.sentiment_score || 'NA'}</td>
                          </tr>

                        ))
                      }
                    </tbody>
                  </table>
                </div>
              </Row>
            </Col>
          </Row>
        </div>
      </>

    );
  }
}

export default MainPageComponent;

