import GaugeChart from "react-gauge-chart";
import React, { Component } from 'react';






class GaugeCharts extends Component {
  constructor() {
    super();
    this.state = {
      name: "React"
    };

  }
  getColors() {
    const { value } = this.props;
    if (value <= 1 / 3) {
       return ["red", "rgb(255 241 224)", "rgb(215 255 215)"];
    } else if (value <= 2 / 3) {
      return ["rgb(255 208 205)", "#F08000", "rgb(215 255 215)"];
    } else {
      return ["rgb(255 208 205)", "rgb(255 241 224)", "#009900"];
    }
  }
  getColor() {
    const { value } = this.props;
    if (value <= 1 / 3) {
       return "red";
    } else if (value <= 2 / 3) {
      return "#F08000";
    } else {
      return "#009900";
    }
  }

  render() {
    return (

      <GaugeChart
        id="gauge-chart3"
        nrOfLevels={3}
        colors={this.getColors()}
        arcWidth={0.3}
        percent={this.props.value}
        textColor={this.getColor()}
        className="custom-gauge-chart"
        // hideText={true} // If you want to hide the text
      />
    );
  }
}



export default GaugeCharts;