import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {signOut} from './Login/Logout.js'

class NavComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };

  }
  render() {
    return (
       <>
        <Navbar expand="sm" fixed="top" className="navbar navbar-style">
            <div className="briologotxt">
              <Navbar.Brand href="/?call-type=inbound">
                
              </Navbar.Brand>
              <div>
                <a className="nav-header">CalliQ</a>
              </div>
              <div className="ms-5">
                  <a className="nav-link cursor-pointer" href="/conversations?call-type=all" >Conversations</a>
              </div>

            </div>
            <div>

              <NavDropdown className="ms-auto me-5"
                title={localStorage.calliq_display_name? localStorage.calliq_display_name: 'Guest'}
                id="basic-nav-dropdown">
                <NavDropdown.Item href={void(0)} onClick={signOut}>Sign out</NavDropdown.Item>
              </NavDropdown>
            </div>
        </Navbar>
      </>
    );
  }
}

export default NavComponent;