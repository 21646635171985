import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class KeyPhrases extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };

  }
  render() {
    return (
        <Row className="white-bg p-3" style={{ width: '100%', height:'50%' }}>

          <h6>Key Phrases</h6>
            <div className="topics-list">
              {this.props.key_phrases.length !== 0 ? this.props.key_phrases.map((each_topic, index) => (
                <span className="pill-class" key={each_topic}> { each_topic }</span>
              )) : <p className="pill-class"> No key Phrases</p>}
          </div>
        </Row>
    );
  }
}

export default KeyPhrases;
