import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { SiConvertio } from "react-icons/si";
import { FaLanguage } from "react-icons/fa6";

class Conversation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flag_english:"yes",
      flag_other:"no"
    };
     this.scrollRef = React.createRef();
    this.scrollInterval = null;
    this.setValueEnglish = this.setValueEnglish.bind(this);
    this.setValueOther = this.setValueOther.bind(this)
  }

  startAutoScroll = (audioDuration) => {
    const scrollDuration = audioDuration * 1000; // 1 minute in milliseconds
    const scrollSpeed = 30; // Adjust the scroll speed
    const startTime = Date.now();

    this.scrollInterval = setInterval(() => {
      if (this.scrollRef.current) {
        this.scrollRef.current.scrollTop += 1; // Scroll down by 1 pixel
      }

      if (Date.now() - startTime > scrollDuration) {
        clearInterval(this.scrollInterval); // Stop scrolling after 1 minute
      }
    }, scrollSpeed);
  };

  stopAutoScroll = () => {
    clearInterval(this.scrollInterval); // Stop scrolling when needed
  };

  endAutoScroll = () => {
    console.log("got it here")
    this.scrollRef.current.scrollTop = 0
  }

  componentDidMount() {
    // Attach the startAutoScroll function to the global window object
    window.startAutoScroll = this.startAutoScroll;
    window.stopAutoScroll = this.stopAutoScroll;
    window.endAutoScroll = this.endAutoScroll;
  }

  componentWillUnmount() {
    this.stopAutoScroll(); // Cleanup on component unmount
    delete window.startAutoScroll; // Remove the global reference
  }

setValueOther() {

      this.setState({ flag_english: "no", flag_other: "yes" })
   
  }



  setValueEnglish() {
 
      this.setState({ flag_english: "yes", flag_other: "no" })

   
  }



  render() {
    return (
      <Row className="conversation-header">
      <div className="conversation-span">

       Conversation
       <span style={{ float:'right', display: (this.props.detected_language.native_language_detected && (this.props.detected_language.native_language_detected != "English" && this.props.detected_language.native_language_detected != "english"))  ? "" : "none" }}>
       <button className="raw-button" onClick={this.setValueOther} style={{ color: this.state.flag_other === "yes" ? "black" : "white"  }}>{this.props.detected_language.native_language_detected}</button> |
       <button className="raw-button" onClick={this.setValueEnglish} style={{ color: this.state.flag_english === "yes" ? "black" : "white"  }} >English</button>
       </span>
      

      </div>
      <div id="results" className="message-body"  ref={this.scrollRef}>
        {this.props.messages.map((eachMessage, index) => (
          <div key={index} className={`speaker-${eachMessage.speaker_tag} each_message_container`}>
            
            {eachMessage.speaker_tag === 'Agent' && (
            <div className="agentClass">
              <div className="speaker_tag">
                {eachMessage.speaker_name.includes("Agent") ? this.props.agent_name && this.props.agent_name!='Unknown'  ? this.props.agent_name : "Agent" : eachMessage.speaker_name ? eachMessage.speaker_name : "Agent" }
                &nbsp;{eachMessage.sentiment_text.sentiment_score > 0.25 && <span className="response-dot sentiment-tag-Positive" title="Below message is Positive"></span>}
                {eachMessage.sentiment_text.sentiment_score >= 0 && eachMessage.sentiment_text.sentiment_score <= 0.25 && (
                  <span className="response-dot sentiment-tag-Neutral" title="Below message is Neutral"></span>
                )}
                {eachMessage.sentiment_text.sentiment_score < 0 && <span className="response-dot sentiment-tag-Negative" title="Below message is Negative"></span>}
    
              </div>
              <div className="each_message">{this.state.flag_english == "no" ? eachMessage.text : eachMessage.text_english}</div>
              </div>
            )}
            {eachMessage.speaker_tag === 'Customer' && (
             <div className="customerClass">
              <div className="speaker_tag">
                {eachMessage.speaker_name.includes("Customer") ? this.props.customer_name && this.props.customer_name!='unknown'  ? this.props.customer_name : "Customer" : eachMessage.speaker_name ? eachMessage.speaker_name : "Customer"  }
                &nbsp;{eachMessage.sentiment_text.sentiment_score > 0.25 && <span className="response-dot sentiment-tag-Positive" title="Below message is Positive"></span>}
                {eachMessage.sentiment_text.sentiment_score >= 0 && eachMessage.sentiment_text.sentiment_score <= 0.25 && (
                  <span className="response-dot sentiment-tag-Neutral" title="Below message is Neutral"></span>
                )}
                {eachMessage.sentiment_text.sentiment_score < 0 && <span className="response-dot sentiment-tag-Negative" title="Below message is Negative"></span>}
              </div>
              <div className="each_message">{this.state.flag_english == "no" ? eachMessage.text : eachMessage.text_english}</div>
              </div>
            )}
            
          </div>
        ))}
      </div>
      </Row>

    );
  }
}

export default Conversation;
