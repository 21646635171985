import React, { Component } from 'react';

class Footer extends Component {
  render() {
    return (
    <footer data-layout="default ">
          <div className="display-inline-block  w-75 textLeft">
            <p className="text-start text-white" id="copyright-text" style={{ marginBottom: '0px' }}>© {(new Date().getFullYear())} Brio Technologies Private Limited.</p>
          </div>
          <div className="w-25 text-end">
            <a target="_blank" className='text-white' href="https://twitter.com/briotech"><i className="fa fa-twitter me-3" aria-hidden="true"></i></a> &nbsp;
            <a target="_blank" className='text-white' href="https://www.facebook.com/briohyd/"><i className="fa fa-facebook me-3" aria-hidden="true"></i></a>  &nbsp;
            <a target="_blank" className='text-white' href="https://in.pinterest.com/pin/brio-technologies--458522805814768809/"><i className="fa fa-pinterest me-3" aria-hidden="true"></i></a>  &nbsp;
            <a target="_blank" className='text-white' href="https://in.linkedin.com/company/brio-technologies-private-limited/"><i className="fa fa-linkedin me-3" aria-hidden="true"></i></a>  &nbsp;
            <a target="_blank" className='text-white' href="https://www.youtube.com/channel/UCfTwtfubl7te-VOOZr-so_g"><i className="fa fa-youtube-play me-3" aria-hidden="true"></i></a> 
          </div>           
    </footer>
    );
  }
}


export default Footer;
