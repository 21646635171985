import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CallDetails from './CallDetails';
import CustomerRequirement from './CustomerRequirement';
import Conversation from './Conversation';
import TopDiscussedTopics from '../TopDiscussedTopics';
import KeyPhrases from '../KeyPhrases';
import AgentMatrix from './AgentMatrix';
import SentimentChart from '../SentimentChart';
import FurtherAction from './FurtherAction';
import ChatIcon from '../ChatIcon';
import Footer from '../Footer';
import axios from 'axios';
import {API_HOST} from '../../settings';
import { PiFileAudioFill } from "react-icons/pi";
import { MdErrorOutline } from "react-icons/md";
import { MdOutlineFileDownloadDone } from "react-icons/md";
import Cookies from "universal-cookie";
import { LuLoader } from "react-icons/lu";
import "noty/lib/noty.css"; 
import "noty/lib/themes/relax.css";
import Pace from 'pace-js'; 
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { VscCallOutgoing } from "react-icons/vsc";
import { VscCallIncoming } from "react-icons/vsc";
import InfiniteScroll from "react-infinite-scroll-component";
import incoming_call_icon from '../../images/incoming-call.png';
import outgoing_call_icon from '../../images/outgoing-call.png';


const cookies = new Cookies();

const Noty = require('noty');

class MainComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    conversation_details:{},
    meta_data:{},
    audio_url:"",
    loader_g:false,
    dummy:false,
    matrix_list:[],
    file_name:"",
    message: "",
    options:[],
    loader_2:false,
    selectedOption:"all",
    bucket: "",
    agent_name:"",
    call_type:"",
    hasMore:"",
    page:1,
    selectedFilter: "all",
    last_doc_inbound: '',
    last_doc_outbound: '',
    call_details: {}
};
this.containerRef = React.createRef();
this.updateParentState = this.updateParentState.bind(this);
this.updateLoader = this.updateLoader.bind(this);
this.getMatrices = this.getMatrices.bind(this);
this.handleConversationDetails = this.handleConversationDetails.bind(this);
this.chatRef = React.createRef();
this.sendMessage = this.sendMessage.bind(this);

}


updateParentState(data,audio) {
    if (data) {
      let json_data;
      try {
        console.log(data)
      } catch (e) {
        console.error("Error parsing JSON data: ", e);
        return;
      }
      this.setState({ conversation_details: data.call_matrix, meta_data:data, audio_url: audio, loader_g: false });
    }
    this.setState({loader_g: false})
  };


  sendMessage = (text) => {
     const formData = new FormData();
      const auth_token = cookies.get("calliq_token")
     formData.append('system_instruction', JSON.stringify(this.state.meta_data));
     formData.append('input_text', text)
     axios.defaults.headers.get['Authorization'] =auth_token
     axios.post(`${API_HOST}/iq/single/chat/enquiry/`, formData, {
       headers: {
         'Content-Type': 'multipart/form-data',
         'Authorization':auth_token

       }
     })
     .then(response => {
        this.chatRef.current.removeItem({ type: 'bot', text: '<div class="loader-dots"></div>' });
       this.chatRef.current.insertChatMessage("bot", response.data.text.replace(/html|```/g, '').replace(/\*\*(.*?)\*\*/g, '<span class="bold-text">$1</span>'))
       console.log(response.data);
     })
     .catch(error => {
       this.chatRef.current.insertChatMessage("bot", "Error")
     });

   }

notify = (msg, message_type) => {

    var type = message_type;
    var position = "topCenter";
    new Noty({
      theme: 'relax',
      text: msg,
      type: type,
      timeout: 3000,
      layout: position,
      animation: {
        open: 'animated bounceInLeft',
        close: 'animated bounceOutUp'
      }
    }).show();
  };

getMatrices(call_type) {
  const auth_token = cookies.get("calliq_token")
  var clear_local_storage = false
  if(this.state.page ==1){
    this.setState({ loader_g: true, conversation_details: "",meta_data:"", message:"", file_name:""});
    axios.defaults.headers.get['Authorization'] =auth_token
    axios.get(`${API_HOST}/conversations/list/?agent_info=${this.state.selectedOption}&call_type=${call_type}&page=${this.state.page}&inbound_last_doc=${this.state.last_doc_inbound}&outbound_last_doc=${this.state.last_doc_outbound}`, {
         headers: {
           'Content-Type': 'multipart/form-data',
           'Authorization': auth_token
         }
       })
      .then(response => {
          if(this.state.page ==1){
              this.setState((prevState) => ({
              matrix_list: [...prevState.matrix_list, ...response.data.data],
              hasMore: response.data.has_more,
              page: prevState.page + 1, // Increment the page number
              loader_g:false,
              options:response.data.options,
              last_doc_inbound: response.data.last_doc_inbound,
              last_doc_outbound:response.data.last_doc_outbound
            }));
            
          }
          
        
      })
      .catch(error => {
        if(error.response.status == 401 ){
          // alert("Token expired")
          clear_local_storage = true
          this.notify("Token expired","error")

        }
        else {
            this.notify("Something went wrong. Try again.",'error')
            clear_local_storage=true
        }
        setTimeout(function(){
              if(clear_local_storage){
                  window.location.href = "/?call-type=inbound"
                  
              }                
          },1000)


        // console.error('There was an error processing the audio:', error);
      });
    }
}

getFilteredMatrices(call_type) {
  const auth_token = cookies.get("calliq_token")
  var clear_local_storage = false
  this.setState({ loader_g: true, last_doc_inbound:'', last_doc_outbound: '', conversation_details: "",meta_data:"", message:"", file_name:""});
  axios.defaults.headers.get['Authorization'] =auth_token
  axios.get(`${API_HOST}/conversations/list/?agent_info=${this.state.selectedOption}&call_type=${call_type}&page=${this.state.page}`, {
       headers: {
         'Content-Type': 'multipart/form-data',
         'Authorization': auth_token
       }
     })
    .then(response => {
        debugger
      
        this.setState({
          matrix_list: response.data.data,
          hasMore: response.data.has_more,
          page: 2, // Increment the page number
          loader_g:false,
          options:response.data.options,
          last_doc_inbound: response.data.last_doc_inbound,
          last_doc_outbound:response.data.last_doc_outbound
        });
        
        

    })
    .catch(error => {
      if(error.response.status == 401 ){
        // alert("Token expired")
        clear_local_storage = true
        this.notify("Token expired","error")

      }
      else {
          this.notify("Something went wrong. Try again.",'error')
          clear_local_storage=true
      }
      setTimeout(function(){
            if(clear_local_storage){
                window.location.href = "/?call-type=inbound"
                
            }                
        },1000)


      // console.error('There was an error processing the audio:', error);
    });
}



fetchMoreData() {
  const auth_token = cookies.get("calliq_token")
  var clear_local_storage = false
  this.setState({ conversation_details: "",meta_data:"", message:"", file_name:""});
  axios.defaults.headers.get['Authorization'] =auth_token
  debugger
  axios.get(`${API_HOST}/conversations/list/?agent_info=${this.state.selectedOption}&call_type=${this.state.call_type}&page=${this.state.page}&inbound_last_doc=${this.state.last_doc_inbound}&outbound_last_doc=${this.state.last_doc_outbound}`, {
       headers: {
         'Content-Type': 'multipart/form-data',
         'Authorization': auth_token
       }
     })
    .then(response => {
      if (response.data && response.data.data) {
        const newData = response.data.data;
          const combinedData = [...this.state.matrix_list, ...newData];

          // Sort both the previous and new data together
          const sortedData = combinedData.sort((a, b) => new Date(b.call_date) - new Date(a.call_date));
          debugger;
        
          this.setState((prevState) => ({
            matrix_list: sortedData,
            hasMore: response.data.has_more,
            page: prevState.page + 1, // Increment the page number
            loader_g:false,
            options:response.data.options,
            last_doc_inbound: response.data.last_doc_inbound,
            last_doc_outbound:response.data.last_doc_outbound
          }));
       
      } else {
        this.setState({ hasMore: false }); // No more data to load
      }
    })
    .catch(error => {
      if(error.response.status == 401 ){
        // alert("Token expired")
        clear_local_storage = true
        this.notify("Token expired","error")

      }
      else {
          this.notify("Something went wrong. Try again.",'error')
          clear_local_storage=true
      }
      setTimeout(function(){
            if(clear_local_storage){
                cookies.remove('calliq_token',{path:'/'})
                localStorage.clear()
                window.location.href = "/?call-type=inbound"
                
            }                
        },1000)


      // console.error('There was an error processing the audio:', error);
    });
}

handleConversationDetails() {
  this.setState({ conversation_details: {}, meta_data:{}, file_name:"" })
}




 componentDidMount() {
    const queryParams = new URLSearchParams(window.location.search);

    const param1 = queryParams.get('call-type');

    this.setState({call_type: param1, selectedFilter: param1});
    this.getMatrices(param1);
        Pace.start();
    const container = this.containerRef.current;

    container.addEventListener('scroll', this.handleScroll); // Attach scroll event to the container
  }

  componentWillUnmount() {
    const container = this.containerRef.current;
     if (container) {
      container.removeEventListener('scroll', this.handleScroll); // Clean up the event listener
    }
    // container.removeEventListener('scroll', this.handleScroll); // Clean up the event listener
  }

handleMatrix = (file_name, call_type) =>{
    window.toggleModal_false();
    this.setState({ loader_2: true, conversation_details: "", meta_data:"", message:"Processing" });
    const formData = new FormData();
    var clear_local_storage = false
    formData.append('file_name', file_name);
    const auth_token = cookies.get("calliq_token")
    axios.defaults.headers.post['Authorization'] =auth_token
    axios.get(`${API_HOST}/conversations/conversation/info/?file_name=${file_name}&call_type=${call_type}`, {
       headers: {
         'Content-Type': 'multipart/form-data',
         'Authorization': auth_token
       }
     })
    .then(response => {
        console.log(response)
        if(Object.keys(response.data.data.call_matrix).length !== 0){
          this.setState({ conversation_details: response.data.data.call_matrix, call_details: response.data.data, meta_data:response.data.data, file_name: file_name, agent_name: response.data.data.agent_name });
          this.setState({ loader_2: false, bucket: response.data.bucket });
          this.chatRef.current.handleClearMessages();
          this.chatRef.current.insertChatMessage("bot", "Your selected call matrix loaded successfully.")
          this.chatRef.current.insertChatMessage("bot", "You can ask questions on selected matrix.")
          this.chatRef.current.updateCurrentId("id")
          Pace.stop();
          console.log("response.data.data", response.data.data)
        }
        else{
          console.log("came")
          this.setState({ file_name: file_name, message: "processing", conversation_details: "", meta_data:""});
        }
        

    })
    .catch(error => {
          if(error.response.status == 401 ){
            // alert("Token expired")
            clear_local_storage = true
            this.notify("Token expired","error")

          }
          else {
              this.notify("Something went wrong. Try again.",'error')
              clear_local_storage=true
          }
          setTimeout(function(){
                if(clear_local_storage){
             
                    window.location.href = "/?call-type=inbound"
                    
                }                
            },1000)


          // console.error('There was an error processing the audio:', error);
        });
    // this.setState({ conversation_details: matrix, file_name: file_name, message: '' });
  }
  

updateLoader() {
  this.setState({ loader_g: false });
}

handleDateFormat(dateString) {
  const date = new Date(dateString);
  
  // Format the date and time using local time
  let formattedDate = date.toLocaleString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' });  // "28 Aug 2024"
  let formattedTime = date.toLocaleString('en-GB', { hour: '2-digit', minute: '2-digit', hour12: false }).slice(0, 5); // "17:13"

  let result = `${formattedDate} ${formattedTime}`;
  return result;
}



  handleChange = (event) => {
    this.setState({ selectedOption: event.target.value, page: 1 });
    setTimeout( () => {
      this.getFilteredMatrices(this.state.call_type);
      }, 200)
  };

  handleScroll = () => {
     const { hasMore } = this.state;
     const container = this.containerRef.current;
     // Check if the user has scrolled to the bottom of the container
     if (hasMore && container.scrollHeight - container.scrollTop <= container.clientHeight + 1) {
   debugger;
   this.fetchMoreData();
}

   };

     handleFilterChange = (event) => {
    const { baseDate } = this.state;
    const selectedFilter = event.target.value;
    this.setState({selectedFilter:event.target.value})
    window.location.href = `/conversations?call-type=${selectedFilter}`
  };
  

  render() {
    return (
      <>
      <div>
        <ChatIcon ref={this.chatRef} updateLoader={this.updateLoader} sendMessage={this.sendMessage} handleConversationDetails={this.handleConversationDetails} updateParentState={this.updateParentState} getMatrices={this.getMatrices}  />
        <div className="container-fluid">
        
          <Row style={{ height: '85vh', padding: '10px' }}>
            <Col lg="3" className="white-bg" style={{ height:'inherit', paddingTop:'10px' }}>
              <div className="d-flex" style={{ flexDirection:'row-reverse', alignItems:'flex-start'}}>
                <p > Sales calls</p>
                <select
                  id="dateFilter"
                  className="dataFilters"
                  value={this.state.selectedFilter}
                  onChange={this.handleFilterChange}
                  style={{ background:'none', width:'107px'}}
                  >
                  <option value="all" style={{ float:'right' }}>All</option>
                  <option value="inbound">Inbound</option>
                  <option value="outbound">Outbound</option>
                </select> 
              </div>
              <div className="d-flex" style={{ flexDirection:'Column'}}>
                <div className="header-section">
                  <p className="header-class">Call Recordings of</p>
                   {Object.keys(this.state.options).length !=0 ? 
                   <>
                  <select
                    id="dropdown"
                    style={{border:'none', background:'white', width:'200px', height: '20px'}}
                    value={this.state.selectedOption}
                    onChange={this.handleChange}
                  >
                    <option key="all" value="all">All</option>
                    {this.state.options.map(option => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select> </>: <></> }
                </div>
                { this.state.loader_g == false ?
                <div className="scroll-container" ref={this.containerRef}>
                  <div>
               
                  { this.state.matrix_list.map((matrix, index) => (
                    <div key={index} className={ this.state.file_name === matrix.input_file ? 'nav-item matrix-list-2  active' : 'nav-item matrix-list-2' } style={{ cursor:'pointer'}} key={index} onClick={() => this.handleMatrix(matrix.input_file, matrix.call_type)}>
                    { matrix.status !=="Error" ? <span style={{ fontSize:'28px' }}>{ matrix.status ==="Processed" ? matrix.call_type ==="outbound" ? <img src={outgoing_call_icon} style={{ width:'18px', height:'18px'}} /> : <img src={incoming_call_icon} style={{ width:'18px', height:'18px', transform: 'rotate(180deg)'}} /> : <LuLoader /> }</span> :
                    <span> <MdErrorOutline /></span> }
                    <span className="matrix-list">
                    <span title={matrix.input_file}>{matrix.input_file.substr(0,35)}{Object.keys(matrix.input_file).length > 35 ? '...' : ''}</span>
                    <span>{this.handleDateFormat(matrix.call_date)}</span>
                    </span>
                    
                    </div>
                  ))}
                  </div>
                  {/* Show a loading message when data is being fetched */}
                  {this.state.hasMore && <div>Loading...</div>}
                </div>
                : <div className="loader"></div> }
              </div>
            </Col>
           <Col lg="9" className="lightgray-bg" style={{ height:'inherit', overflowY: Object.keys(this.state.conversation_details).length !==0 ? 'scroll' : 'hidden', padding: '10px', paddingTop: '0px' }} >
    
              { Object.keys(this.state.conversation_details).length !== 0 ?      
                <>
                  <Row className="FARow" >
                    <div className="lightgray-bg" style={{ height:'inherit' }}>
                      <FurtherAction agent_matrix={this.state.conversation_details.agent_matrix}
                        call_key_matrix={this.state.conversation_details.call_key_matrix}
                        call_type={this.state.call_type} />
                    </div>
                  </Row>
              <Row className="FirstRow" >
                <Col lg="8"  className="lightgray-bg" style={{ height:'inherit' }}>
                
                  <AgentMatrix agent_matrix={this.state.conversation_details.agent_matrix}
                              call_key_matrix={this.state.conversation_details.call_key_matrix}
                              call_type={this.state.call_type} />

                </Col>

                <Col lg="4"  className="lightgray-bg" style={{ height:'inherit' }}>
               
                  <CallDetails 
                    agent_name={this.state.agent_name}
                    customer_name={this.state.meta_data.customer_name}
                    customer_info={this.state.conversation_details.customer_info}
                    tentative_date={this.state.conversation_details.call_key_matrix.tentative_date}
                    customer_interest={this.state.conversation_details.call_key_matrix.customer_interest}
                    targeted_person={this.state.conversation_details.call_key_matrix.targeted_person}
                    agent_matrix={this.state.conversation_details.agent_matrix}
                    file_name={this.state.file_name}
                    bucket={this.state.bucket}
                    call_type={this.state.call_type}
                    call_key_matrix={this.state.conversation_details.call_key_matrix}
                    call_details={this.state.call_details}/>
                  
                 </Col>
                
              </Row>
              <Row className="SecondRow" style={{paddingTop: '15px' }}>

              <Col lg="6" className="lightgray-bg" style={{ height:'98%', paddingLeft: '7px', paddingRight:'18px' }}>
                    <CustomerRequirement agent_response={this.state.conversation_details.call_key_matrix.agent_action}
                      customers_requirement={this.state.conversation_details.call_key_matrix.customer_main_requirement_product}
                      call_summary={this.state.conversation_details.call_key_matrix.call_summary}
                      call_type={this.state.call_type}
                      customer_requirment_product={this.state.conversation_details.call_key_matrix.customer_main_requirement_product_name}

                    />

                </Col>

                <Col lg="6" className="lightgray-bg" style={{ height:'100%', paddingLeft:'18px'}}>
                    {Object.keys(this.state.conversation_details).length !== 0 ? <Conversation messages={this.state.conversation_details.messages}
                    agent_name={this.state.conversation_details.agent_info.name}
                    customer_name={this.state.conversation_details.customer_info.name}
                    detected_language={this.state.conversation_details} />
                    : <></> }
                </Col>
              
              </Row>
              <Row className="ThirdRow">
                <Col lg="6" className="lightgray-bg" style={{ marginLeft:'0px',paddingLeft: '0px', marginBottom:'10px' }}>
                  <Row>
                  <SentimentChart conversation_details={this.state.conversation_details} />
                  </Row>
                </Col>

                 <Col lg="6" className="lightgray-bg" style={{ paddingLeft:'10px', paddingRight: '0px', overflowY: 'scroll',    boxShadow: "-1px 1px 7px 0px #00000029", marginBottom:'10px' }}>
                  <div className="white-bg" style={{ height: '100%' }}>
                    <TopDiscussedTopics style={{ height:'50%' }} top_discussed_topics={this.state.conversation_details.call_key_matrix.top_discussed_topics}/>
                    <KeyPhrases style={{ height:'50%' }} key_phrases={this.state.conversation_details.call_key_matrix.key_phrases} />
                  </div>
                </Col>
                
              </Row> 
            </>




              :
              <div style={{ padding: '14px' }}> {this.state.message ? <h3>Processing</h3> :  
                <div className="w100 white-bg-lite m-0 container">
                  <div className="p-5 d-flex justify-content-center row">
                    <div className="col-lg-8">
                      <div className="p-2 row">
                        <h3>CalliQ is a powerful call analysis tool that utilizes Gemini to analyze acoustic features and sentiment from audio data.</h3>
                      </div>
                    </div>
                  </div>
                </div>  }
              </div>}
            </Col>
          </Row>
        </div>
      </div>
       
       </>
    );
  }
}

export default MainComponent;