import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyA1GseDkCKXi_gQQRhI2EFaeZgTop8mMzU",
    authDomain: "calliq-prod.firebaseapp.com"
  };
const firebaseApp = initializeApp(firebaseConfig);


const g_provider = new GoogleAuthProvider();

g_provider.setCustomParameters({   
    prompt : "select_account "
});

export const firebase_auth = getAuth();

export const google_provider = g_provider;

export const firebaseSignInWithPopup = signInWithPopup;
