import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


class CustomerRequirement extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };

  }
  render() {
    return (
          <Row className="white-bg customer-info p-3" style={{ height:'391px' }} >

            {this.props.call_type == 'inbound'?
            <>
            <Row>
            <div className="d-flex" style={{justifyContent: 'space-between'}}>
              <h6>Prospect Requirement</h6>
              <p>{this.props.customer_requirment_product ? this.props.customer_requirment_product : '' }</p>
             </div>
            <p>{this.props.customers_requirement ? this.props.customers_requirement : "NA" }</p>
            </Row>
            <Row>
            <h6>Agent Response</h6>
            <p>{this.props.agent_response ? this.props.agent_response : "NA"}</p>
            </Row>
            </>
            :
            <>
             <Row>
            <h6>Agent Pitch</h6>
            <p>{this.props.agent_pitch ? this.props.agent_pitch : "NA" }</p>
            </Row>
            <Row>
            <h6>Customer Response</h6>
             <p>{this.props.customer_response ? this.props.customer_response : "NA"}</p>
            </Row>
            </>
          }
            <Row>
            <h6>Call Summary</h6>
            <p>{this.props.call_summary}</p>
            </Row>
            
        </Row>
    );
  }
}

export default CustomerRequirement;
