import React, { Component } from 'react';
import CanvasJSReact from '@canvasjs/react-charts';

const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

class SentimentChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      speaker_1_data: [],
      speaker_2_data: []
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { conversation_details } = nextProps;
    if (conversation_details !== prevState.conversation_details) {
      let speaker_1_counter = 0;
      let speaker_2_counter = 0;
      const speaker_1_data = [];
      const speaker_2_data = [];

      conversation_details.messages.forEach((each_message) => {
        if (each_message.speaker_tag === "Agent") {
          speaker_1_data.push({
            x: speaker_1_counter,
            y: parseFloat(each_message.sentiment_text.sentiment_score),
            info: `Agent  : ${each_message.text_english}<br>`
          });
          speaker_1_counter += 1;
        } else if (each_message.speaker_tag === "Customer") {
          speaker_2_data.push({
            x: speaker_2_counter,
            y: parseFloat(each_message.sentiment_text.sentiment_score),
            info: `Customer : ${each_message.text_english}`
          });
          speaker_2_counter += 1;
        } else {
          console.error('Something went wrong. Contact Admin.');
        }
      });

      return {
        speaker_1_data,
        speaker_2_data,
        conversation_details // Keep the latest props for comparison
      };
    }
    return null; // No state update
  }

  render() {
    const { speaker_1_data, speaker_2_data } = this.state;

    const options = {
      animationEnabled: true,
      theme: "light2", // "light1", "dark1", "dark2"
      title: {
        text: "Sentiment Analysis",
        fontSize: 15
      },
      axisY: {
        title: "Sentiment",
        crosshair: {
            enabled: true
          },
        labelFormatter: function ( e ) {
                 if (e.value > 0.25) {
                   return 'Positive'
                 } else if(e.value >= -0.25 && e.value <= 0.25) {
                  return 'Neutral'
                 } else if(e.value < -0.25) {
                  return "Negative"
                 } else {
                  return "neutral"
                 }
           },
           interval: 1,
        
      },
      axisX: {
        title: "Conversation count",
        crosshair: {
            enabled: true,
            snapToDataPoint: true
          },
         fontSize:10

      },
      toolTip:{
          shared:true,


        },

      data: [
        {
          type: "line",
          toolTipContent: "{info}",
          showInLegend: true,
          name: "Agent",
          markerType: "square",
          color: "#fedf1a",
          dataPoints: speaker_1_data
        },
        {
          type: "line",
          toolTipContent: "{info}",
          showInLegend: true,
          name: "Customer",
          color: "#7CB9E8",
          lineDashType: "dash",
          dataPoints: speaker_2_data
        }
      ]
    };

    return (
        <div>

        <CanvasJSChart  options={options}  />

        </div>

    );
  }
}

export default SentimentChart;
