import React, { Component, createRef } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPaperPlane, faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { FaPaperPlane, FaPaperclip } from "react-icons/fa";
import { MdOutlineClose } from "react-icons/md";
// import { TfiReload } from "react-icons/tfi";
import { TbReload } from "react-icons/tb";
import robotIconCircle from '../datafiles/robotIconCircle.png';
import axios from 'axios';
import '../Styles/ChatIconComponent.css';
import '../Styles/myApp.css';
import {API_HOST} from '../settings';
import Cookies from "universal-cookie";


const cookies = new Cookies();

class ChatIcon extends Component {
    constructor(props) {
    super(props);
    this.iconDivRef = createRef();
    this.overAllContainerRef = createRef();
    this.endOfMessagesRef = createRef();
    this.state = {
        inputValue: '',
        isModalOpen:true,
        messages: [],
        positionBottom: 56,
        positionRight: 15,
        mouseDownPosition: { x: 0, y: 0 },
        isDragging: false,
        clickTimeout: null,
        current_id: ""
        };

    // Bind methods
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSendClick = this.handleSendClick.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.toggleModal_false = this.toggleModal_false.bind(this);
    this.toggleModal_true = this.toggleModal_true.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleClearMessages = this.handleClearMessages.bind(this)
    this.handleFileChange = this.handleFileChange.bind(this)
    this.handleFurtherActionClick = this.handleFurtherActionClick.bind(this)
    this.removeItem = this.removeItem.bind(this)
    }

    toggleModal() {
        this.setState((prevState) => ({ isModalOpen: !prevState.isModalOpen }));
    }
    toggleModal_true() {
        this.setState((prevState) => ({ isModalOpen: true }));
    }

    toggleModal_false() {
        this.setState((prevState) => ({ isModalOpen: false }));
    }
    // Handle input change
    handleInputChange(event) {
        this.setState({ inputValue: event.target.value });
    }

    handleClearMessages (){
        
            this.setState({ messages: [] });
        
    }


    // draggable
    componentDidMount() {
        document.addEventListener('mouseup', this.handleMouseUp);
        document.addEventListener('mousemove', this.handleMouseMove);

        const iconDiv = this.iconDivRef.current;
        iconDiv.addEventListener('mousedown', this.handleMouseDown);
        this.adjustPosition();
        window.addEventListener('resize', this.adjustPosition);
        window.toggleModal_false = this.toggleModal_false;
        window.toggleModal_true = this.toggleModal_true;
        window.handleFurtherActionClick = this.handleFurtherActionClick;
        this.insertChatMessage('bot','Please select a call recording.')
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.adjustPosition);
        const iconDiv = this.iconDivRef.current;
        iconDiv.removeEventListener('mousedown', this.handleMouseDown);
        document.removeEventListener('mouseup', this.handleMouseUp);
        document.removeEventListener('mousemove', this.handleMouseMove);
    }

    handleMouseDown(e) {
        const interactiveElements = ['input', 'textarea', 'select', 'button', 'label'];
        if (interactiveElements.includes(e.target.tagName.toLowerCase())) {
            return; // Prevent dragging when clicking on interactive elements
        }

        this.setState({
          mouseDownPosition: { x: e.clientX, y: e.clientY },
          isDragging: true,
          clickTimeout: setTimeout(() => {
            this.setState({ clickTimeout: null });
          }, 200), // Delay to detect clicks vs. drag
        });

        document.addEventListener('mouseup', this.handleMouseUp);
        document.addEventListener('mousemove', this.handleMouseMove);
    }


    handleMouseMove = (e) => {
        if (!this.state.isDragging) return;
        e.preventDefault();
        const { positionBottom, positionRight, mouseDownPosition, isDragging } = this.state;
        const iconDiv = this.iconDivRef.current;
        if (!iconDiv) return;

        if (iconDiv && isDragging) {
            const newRight = positionRight - (e.clientX - mouseDownPosition.x);
            const newBottom = positionBottom - (e.clientY - mouseDownPosition.y);

          // Make sure the element stays within the window
          const maxRight = window.innerWidth - iconDiv.offsetWidth;
          const maxBottom = window.innerHeight - iconDiv.offsetHeight;

          this.setState({
            positionRight: Math.max(0, Math.min(newRight, maxRight)),
            positionBottom: Math.max(0, Math.min(newBottom, maxBottom)),
            mouseDownPosition: { x: e.clientX, y: e.clientY },
            isDragging: true,
          });
        }
    };

    handleMouseUp() {
        if (this.state.isDragging) {
            this.setState({ isDragging: false });
        } 
        else if (this.state.clickTimeout) {
        clearTimeout(this.state.clickTimeout);
        this.setState({ clickTimeout: null });
        }
        document.removeEventListener('mouseup', this.handleMouseUp);
        document.removeEventListener('mousemove', this.handleMouseMove);
    }

    handleClick = (e) => {
         const targetTagName = e.target.tagName.toLowerCase();
        const interactiveElements = ['input', 'textarea', 'select', 'button'];
        if (this.state.clickTimeout === null && !interactiveElements.includes(targetTagName)) {
        this.toggleModal();
    }
    };

    componentDidUpdate(prevProps, prevState) {
         this.adjustPosition();
        // Scroll to the bottom when new messages are added
        if (prevState.messages.length !== this.state.messages.length) {
            this.scrollToBottom();
        }
    }

    insertChatMessage = (user, message)=>{
        this.setState(prevState => ({
            messages: [...prevState.messages, { type: user, text: message }]
        }));
        
    }

    handleFileChange = async(event) => {
        const file = event.target.files[0];
        let reader = new FileReader();

        if (file) {

            this.props.updateLoader();
            this.props.handleConversationDetails();
            reader.onload = async (e) => {

            const audioSrc = e.target.result;
            const msg = `
                <div class="file-detail">
                    <audio class="audio-element" src="${audioSrc}" controls></audio>                
                    <h6>${file.name}</h6>
                    <p>Size: ${this.filesizes(file.size)}</p>
                </div>
            `;
            
            this.setState(prevState => ({
                messages: [...prevState.messages, { type: 'user', text: msg }],

            }));
                const formData = new FormData();
                formData.append('file', reader.result.split(',')[1]);
                formData.append('input_type', file.type);
                formData.append('file_name', file.name);
                try {
                    this.setState(prevState => ({
                        messages: [...prevState.messages, { type: 'bot', text: "Audio file is uploading..." }]
                    }));
                    const auth_token = cookies.get("calliq_token")
                    axios.defaults.headers.post['Authorization'] =auth_token
                    const response = await axios.post(`${API_HOST}/speech`, formData, {
                      headers: {
                        'Content-Type': 'multipart/form-data',
                      },
                    });
                    console.log('Audio uploaded to storage successfully.');
                    console.log(response)
                    this.props.getMatrices();
                    this.setState(prevState => ({
                        messages: [...prevState.messages, { type: 'bot', text: "Audio file uploaded" }]
                    }));
                } catch (error) {
                    console.error('Error uploading file:', error);
                    this.setState(prevState => ({
                        messages: [...prevState.messages, { type: 'bot', text: "Error processing Audio." }]
                    }));
                }
            };
            reader.readAsDataURL(file);
        }
    };




   filesizes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    handleFurtherActionClick = (inputValueText) =>{
        const { inputValue, messages } = this.state;
        this.toggleModal_true()
        this.setState({
            messages: [...messages, { type: 'user', text: inputValueText }],
            inputValue: ''
        });
        
        this.props.sendMessage(inputValueText)
    }


    removeItem = (itemToRemove) => {
        
        itemToRemove.text = itemToRemove.text.replace('\"', '"' )
        console.log(this.state.messages)
      this.setState((prevState) => ({
        messages: prevState.messages.filter((item) => item.text !== itemToRemove.text),
      }));
    };

    handleSendClick = () => {
           
            const { inputValue, messages } = this.state;
            if (inputValue.trim() === '') return;

            this.setState({
                messages: [...messages, { type: 'user', text: inputValue }],
                inputValue: ''
            });
            this.setState(prevState => ({
                messages: [...prevState.messages, { type: 'bot', text: '<div class="loader-dots"></div>' }]
            }));
            // Simulate bot reply after a short delay
            if(this.state.current_id === ""){
                setTimeout(() => {
                    this.setState(prevState => ({
                        messages: [...prevState.messages, { type: 'bot', text: 'Hi! Please select a call recording' }]
                    }));
                }, 1000);
            }else{
                this.props.sendMessage(inputValue)
            }
        };
        updateCurrentId = (current_id)=>{
            this.setState({current_id: current_id})
        }


    handleKeyDown = (event) => {
        event.stopPropagation();
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent default form submission behavior
            this.handleSendClick();
        }
    };

    scrollToBottom = () => {
        if (this.endOfMessagesRef.current) {
            this.endOfMessagesRef.current.scrollIntoView({ behavior: 'smooth' });

        }
        
    };

    adjustPosition = () => {
        const parent = this.iconDivRef.current;
        const child = this.overAllContainerRef.current;
        if (!parent || !child) return;

        const parentRect = parent.getBoundingClientRect();
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;
        const maxRight = windowWidth - parentRect.width;
        const maxBottom = windowHeight - parentRect.height;

        if (parentRect.right <= 425 && parentRect.bottom <= 550) {
            console.log('wlhl')
            child.style.left = `${Math.max(0, Math.min(50, 100))}px`;
            child.style.top = `${Math.max(0, Math.min(50, 100))}px`;
            child.style.right = '';
            child.style.bottom = '';
        } 
         if (parentRect.right >= 425 && parentRect.bottom >= 550) {
            console.log('wchc')
            child.style.right = `${Math.max(0, Math.min(50,100))}px`;
            child.style.bottom = `${Math.max(0, Math.min(50,100 ))}px`;
            child.style.left = '';
            child.style.top = '';
        } 
         if (parentRect.right <= 425 && parentRect.bottom >= 550) {
            console.log('wlhc')
            child.style.right = '';
            child.style.top = '';
            child.style.left = `${Math.max(0, Math.min(50, 100))}px`;;
            child.style.bottom = `${Math.max(0, Math.min(50, 100))}px`;;
        }
         if (parentRect.right >= 425 && (parentRect.bottom) <= 550) {
            console.log('wchl')
            child.style.left = '';
            child.style.bottom = '';
            child.style.right = `${Math.max(0, Math.min(50, 100))}px`;;
            child.style.top = `${Math.max(0, Math.min(50, 100))}px`;;
        }
         if (parentRect.right <= 425 && ((windowHeight - parentRect.bottom) >= 550)) {
            console.log('wldyna')
            child.style.right = '';
            child.style.bottom = '';
            child.style.left = `${Math.max(0, Math.min(50, 100))}px`;;
            child.style.top = `${Math.max(0, Math.min(50, 100))}px`;;
        }
    };

    render() {
        const { isModalOpen, inputValue, messages, positionBottom, positionRight } = this.state;
         const childStyle = {
            display: isModalOpen ? 'block' : 'none',
            pointerEvents: isModalOpen ? 'auto' : 'none', // Disable pointer events when hidden
        };
        return (
                <div className="iconDiv"  ref={this.iconDivRef} onClick={this.handleClick} style={{
                      bottom: `${positionBottom}px`,
                      right: `${positionRight}px`,
                     
                    }}>
                    <div className="chatIconi">
                        <div className="chatIcon" id="chatIcon" onClick={this.toggleModal}>
                            {isModalOpen ? (
                                <svg id="closeIconSvg" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 19 18" className="conversations-visitor-close-icon">
                                    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                                        <g fill="#33475b" transform="translate(-927 -991) translate(900.277 962)">
                                            <g transform="translate(27 29)">
                                                <path d="M10.627 9.013l6.872 6.873.708.707-1.415 1.414-.707-.707-6.872-6.872L2.34 17.3l-.707.707L.22 16.593l.707-.707L7.8 9.013.946 2.161l-.707-.708L1.653.04l.707.707L9.213 7.6 16.066.746l.707-.707 1.414 1.414-.707.708-6.853 6.852z"></path>
                                            </g>
                                        </g>
                                    </g>
                                </svg>


                            ) : (
                                <svg id="chatIconSvg" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 39 37" className="conversations-visitor-open-icon">
                                    <defs>
                                        <path id="conversations-visitor-open-icon-path-1:r0:" d="M31.4824242 24.6256121L31.4824242 0.501369697 0.476266667 0.501369697 0.476266667 24.6256121z"></path>
                                    </defs>
                                    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                                        <g transform="translate(-1432 -977) translate(1415.723 959.455)">
                                            <g transform="translate(17 17)">
                                                <g transform="translate(6.333 .075)">
                                                    <path fill="#33475b" d="M30.594 4.773c-.314-1.943-1.486-3.113-3.374-3.38C27.174 1.382 22.576.5 15.36.5c-7.214 0-11.812.882-11.843.889-1.477.21-2.507.967-3.042 2.192a83.103 83.103 0 019.312-.503c6.994 0 11.647.804 12.33.93 3.079.462 5.22 2.598 5.738 5.728.224 1.02.932 4.606.932 8.887 0 2.292-.206 4.395-.428 6.002 1.22-.516 1.988-1.55 2.23-3.044.008-.037.893-3.814.893-8.415 0-4.6-.885-8.377-.89-8.394"></path>
                                                </g>
                                                <g fill="#33475b" transform="translate(0 5.832)">
                                                    <path d="M31.354 4.473c-.314-1.944-1.487-3.114-3.374-3.382-.046-.01-4.644-.89-11.859-.89-7.214 0-11.813.88-11.843.888-1.903.27-3.075 1.44-3.384 3.363C.884 4.489 0 8.266 0 12.867c0 4.6.884 8.377.889 8.393.314 1.944 1.486 3.114 3.374 3.382.037.007 3.02.578 7.933.801l2.928 5.072a1.151 1.151 0 001.994 0l2.929-5.071c4.913-.224 7.893-.794 7.918-.8 1.902-.27 3.075-1.44 3.384-3.363.01-.037.893-3.814.893-8.414 0-4.601-.884-8.378-.888-8.394"></path>
                                                </g>
                                            </g>
                                        </g>
                                    </g>
                                </svg>
                            )}
                        </div>
                    </div>
                    <div className="overAllContainer" ref={this.overAllContainerRef} style={childStyle}>

                        {isModalOpen ? (
                        <div className="mainContainer">
                            <div className="headerContext" style={{ borderRadius: '10px 10px 0px 0px'}} >
                                <div className="logoTest">
                                    <img src="https://www.briotech.com/wp-content/uploads/2022/04/Favicon1.png" alt="Brio logo"/>
                                    <h5>CalliQ Assistant</h5>
                                </div >
                                <div className="emptydiv"></div>
                                <div className="reloadClose">
                                    <button type="submit" onClick={() => {window.location.reload(false)}} >
                                        <TbReload size={14}/>
                                    </button>
                                     <button type="submit" onClick={this.toggleModal}>
                                        <MdOutlineClose  size={14}/>
                                    </button>

                                </div>
                            </div>
                            <div className="midContext" style={{ borderRadius: '0px' }}>
                                {messages.map((message, index) => (
                                    <div key={index} className={`message-container ${message.type}`}>
                                        {message.type === 'bot' && (
                                            <div className="bot-message">
                                                <img src={robotIconCircle} className='bot-icon' alt="Chatbot Icon" />
                                                <div className="message-bot" dangerouslySetInnerHTML={{ __html: message.text }}>
                                                </div>
                                            </div>
                                        )}
                                        {message.type === 'user' && (
                                            <div className="user-message">
                                                <span role="img" aria-label="user emoji" style={{ marginRight: 5 }} >🙋</span>
                                                <div className="message-user" dangerouslySetInnerHTML={{ __html: message.text }}>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                ))}
                                <div ref={this.endOfMessagesRef} />
                            </div>

                            <div className="footerContext" style={{ borderRadius: '0px 0px 10px 10px'}}>
                                <div className="input-container" onClick={(e) => e.stopPropagation()}>
                                    <input
                                      type="text"
                                      value={inputValue}
                                        onKeyDown={(e) => { e.stopPropagation(); this.handleKeyDown(e); }}
                                        onChange={(e) => { e.stopPropagation(); this.handleInputChange(e); }}

                                      placeholder="Type your message"/>
                                     <button type="submit" onClick={(e) => { e.stopPropagation(); this.handleSendClick(); }}>
                                        <FaPaperPlane />
                                    </button>


                                </div>
                            </div>
                        </div>):(<div></div>)}
                    </div>
                </div>
            );
        }
}

export default ChatIcon;