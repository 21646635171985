import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'font-awesome/css/font-awesome.min.css';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { IoIosMail } from "react-icons/io";
import { MdOutlinePendingActions } from "react-icons/md";
import { MdContactPhone } from "react-icons/md";
import { HiOutlineMail } from "react-icons/hi";



class CallDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agent_img:"https://www.briotech.com/wp-content/uploads/2022/04/Favicon1.png",

     
};
this.audioRef = React.createRef();
 

  }

    handleAudioPlay = (meta) => {
    const {duration} = meta.target;
    console.log(Number.parseInt(duration, 10),"duration");
    if (window.startAutoScroll) {
      window.startAutoScroll(Number.parseInt(duration, 10)); // Start auto-scrolling when audio starts playing
    }
  };

  stopScrollBar = () => {
    window.stopAutoScroll();
  }

  endScrollBar = () =>{
    console.log("End scroll")
    window.endAutoScroll();
  }

  render() {
    return (
      <Row className="white-bg call-details" style={{ height:'100%', width:'100%', marginLeft:'0px' }}>
      <div className="heading-callinfo p-3" >
      <Col lg="3">
        <h6>Call Info</h6>
        </Col>
        <Col lg="9">
        <div><audio className="audio-class" onPlay={this.handleAudioPlay} onPause={this.stopScrollBar} onEnded={this.endScrollBar} src={ this.props.file_name ? "https://storage.googleapis.com/"+this.props.bucket+"/" + this.props.file_name : "" } controls>
            </audio></div>
        </Col>

        </div>
        <div className="call-info-body">
        <div className="d-flex white-bg justify-content-between align-items-center" style={{ width:'100%' }}>
            
             <p className="Col-lg-6" style={{ width:'100%' }}><img className="agent_img" src={ this.state.agent_img } />&nbsp;
             { this.props.call_details.call_matrix.agent_info.name ? this.props.call_details.call_matrix.agent_info.name : 'Agent' }</p>
             <p className="Col-lg-6" style={{ width:'100%' }}><span className="user"><i className='fa fa-user'></i></span>&nbsp;
             <span>{ isNaN(this.props.customer_info.name) && this.props.customer_info.name ? this.props.customer_info.name : 'Customer' }</span></p>
        </div>
         <div className="d-flex  white-bg justify-content-between align-items-center" style={{ width:'100%' }}>
            
             <p className="Col-lg-6" style={{ width:'100%' }}><MdContactPhone style={{ fontSize: '24px'}} />&nbsp;
             { this.props.call_details.customer_number ? this.props.call_details.customer_number : '-' }</p>
             <p className="Col-lg-6" style={{ width:'100%' }}><HiOutlineMail style={{ fontSize: '24px'}} />&nbsp;
             <span title={ this.props.customer_info.email_id }>{ this.props.customer_info.email_id ? this.props.customer_info.email_id.length > 14 ? this.props.customer_info.email_id.slice(0,14) + "...": this.props.customer_info.email_id   : "-" }</span></p>
        </div>
        <div className="d-flex p-2 white-bg justify-content-between align-items-center" style={{ width:'100%' }}>
           
            <p className="call-info-common-title Col-lg-6"  data-title="Tentaive Date" style={{ width:'100%' }}> 
            <span  className="tentative_date" style={{ fontSize: '24px' }}><i className='fa fa-calendar' ></i> </span>
            &nbsp; { this.props.tentative_date.date ? this.props.tentative_date.date : "-" }</p>
            <p className="call-info-common-title Col-lg-6"  data-title="Company Name" style={{ width:'100%' }} >
            <span className="customer_company" style={{ fontSize: '24px' }}> <i className='fa fa-building-o'></i></span>
            &nbsp; <span>{ this.props.customer_info.company_name ? this.props.customer_info.company_name : "-" }</span></p>
        </div>
        </div>
        <hr className="horizontal-division" style={{ width: '80%' }} />
        <Row style={{ marginLeft:'0px', borderRadius:'10px' }}>
        <h6>Customer matrix</h6>
        <Row>
        <Col lg="6" className="white-bg p-2" style={{ width:'50%'}} >
        <p className="customerMatrix">{ this.props.customer_interest.flag=="Yes" ? <span className="parent-class-customer-matrix" title={ this.props.customer_interest.reason_for_yes } ><span className="right-mark extended-css">✔</span>&nbsp;Interested</span> :  <span className="parent-class-customer-matrix" title={ this.props.customer_interest.reason_for_no }><span className="wrong-mark extended-css">✖</span>&nbsp;Not Interested</span>  } </p>
        <p className="customerMatrix" >{ this.props.customer_info.customer_hold.flag=="Yes" ? <span className="parent-class-customer-matrix" title={ this.props.customer_info.customer_hold.reason_for_yes }><span className="wrong-mark extended-css">✖</span>&nbsp;Hold</span> : <span className="parent-class-customer-matrix" title={ this.props.customer_info.customer_hold.reason_for_no }><span className="right-mark extended-css">✔</span>&nbsp;No Hold</span>}</p>
        </Col>
        <Col lg="6" className="white-bg p-2" style={{ width:'50%'}} >

        <p className="customerMatrix">{ this.props.targeted_person.flag=="Yes" ?  <span className="parent-class-customer-matrix" title={ this.props.targeted_person.reason_for_yes } ><span className="right-mark extended-css">✔</span>&nbsp;Targeted Person</span> :  <span className="parent-class-customer-matrix" title={ this.props.targeted_person.reason_for_no } ><span className="wrong-mark extended-css">✖</span>&nbsp;Targeted Person</span> }</p>
        <p className="customerMatrix">{ this.props.customer_info.customer_background_noise.flag=="Yes" ? <span className="parent-class-customer-matrix" title={ this.props.customer_info.customer_background_noise.reason_for_yes } ><span className="wrong-mark extended-css">✖</span>&nbsp;Background noise</span> : <span className="parent-class-customer-matrix" title={ this.props.customer_info.customer_background_noise.reason_for_no } ><span className="right-mark extended-css">✔</span>&nbsp;No Background noise</span>}</p>

          
        </Col>
        </Row>
         
         
        </Row>
        </Row>
      
       
    );
  }
}

export default CallDetails;
