import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import './Styles/myApp.css';
import history from './history';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.js';

import PrivateRoute from './PrivateRoute';
import MainComponent from './Pages/conversations/MainComponent';
import MainPageComponent from './Pages/home/MainPageComponent';
import Footer from './Pages/Footer';
import NavComponent from './Pages/NavComponent';
import LoginPage from './Pages/Login/LoginPage'

function App() {
  return (
    <React.StrictMode>
      <Router history={history}>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/:call-type?"
        element={
            <div className="container-fluid">
              <NavComponent />
              <div className="main-content">
                <MainPageComponent />
              </div>
              <Footer />
            </div>
          } />
          <Route path="/conversations/:call-type?"
        element={
              <div className="container-fluid">
                <NavComponent />
                <div className="main-content">
                  <MainComponent />
                </div>
                <Footer />
              </div>
          } />
        </Routes>
      </Router>
    </React.StrictMode>
  );
}

export default App;