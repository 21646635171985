import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GaugeCharts from './GaugeCharts';
import Rating from './rating';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { IoIosMail } from "react-icons/io";
import { MdOutlinePendingActions } from "react-icons/md";
// import CircularProgress from 'react-native-circular-progress-indicator';

class AgentMatrix extends Component {
  constructor(props) {
    super(props);
    this.state = {
      score:78
    };

  }
  getStrokeColor(value) {
    if (value <= 25) {
      return 'red';
    } else if (value <= 80) {
      return '#F08000';
    } else if (value <= 100) {
      return '#009900';
    }
  }

  sendInputMessage() {
    window.handleFurtherActionClick("What is further action to be done as agent to this call?");
  }

  render() {
        const value = this.props.agent_matrix.overall_score_of_agent;
    const strokeColor = this.getStrokeColor(value);
    return (
        <Row className="white-bg" style={{  width:'100%', height: '100%', marginLeft: '2px' }}>
 
        <h6 className="Agent-heading" style={{ paddingLeft: '28px' }}>Agent Matrix</h6> 
        
        <div className="agent-matrix">
      
          <Col lg="2">
          <div className="d-flex" style={{ flexDirection: 'column', alignItems: 'center' }}>
          <CircularProgressbar
            value={value}
            text={`${value}%`}
            styles={{
              path: {
                stroke: strokeColor,
              },
              text: {
                fill: strokeColor,
              },
            }}
            />
            <p className="title-css" data-title={this.props.agent_matrix.agent_overall_performance}>Overall</p>
          </div>
          </Col>
          <Col lg="2">
            <div className="d-flex" style={{ flexDirection: 'column', alignItems: 'center' }}>{ !["null", "None", null].includes(this.props.agent_matrix.agent_pitch_quality.score) ? <GaugeCharts value={(this.props.agent_matrix.agent_pitch_quality.score/this.props.agent_matrix.agent_pitch_quality.total_score)} /> : <div className="circle" >NA</div> }<p className="title-css" data-title={this.props.agent_matrix.agent_pitch_quality.action_took ? this.props.agent_matrix.agent_pitch_quality.action_took : this.props.agent_matrix.agent_pitch_quality.action_took}>  Pitch Quality</p></div>
          </Col>   
          <Col lg="2">
            <div className="d-flex" style={{ flexDirection: 'column', alignItems: 'center' }}>{ !["null", "None", null].includes(this.props.agent_matrix.probing_questions_skill.score ) ? <GaugeCharts value={(this.props.agent_matrix.probing_questions_skill.score/this.props.agent_matrix.probing_questions_skill.total_score)} /> : <div className="circle" style={{ marginLeft: '23px', marginTop: '10px' }}>NA</div> }<p className="title-css" data-title={this.props.agent_matrix.probing_questions_skill.action_took ? this.props.agent_matrix.probing_questions_skill.action_took : this.props.agent_matrix.probing_questions_skill.action_took} style={{ marginLeft:'32px' }}>Probing Questions</p></div>
          </Col> 
          <Col lg="2">
            <div className="d-flex" style={{ flexDirection: 'column', alignItems: 'center' }}>{ !["null", "None", null].includes(this.props.agent_matrix.agent_greetings_at_start_and_end.score ) ? <GaugeCharts value={(this.props.agent_matrix.agent_greetings_at_start_and_end.score/this.props.agent_matrix.agent_greetings_at_start_and_end.total_score)} /> : <div className="circle" style={{ marginLeft: '23px' }}>NA</div> }<p className="title-css" data-title={this.props.agent_matrix.agent_greetings_at_start_and_end.action_took ? this.props.agent_matrix.agent_greetings_at_start_and_end.action_took : this.props.agent_matrix.agent_greetings_at_start_and_end.action_took}>Greeting</p></div>
          </Col> 
          <Col lg="2">
            <div className="d-flex" style={{ flexDirection: 'column', alignItems: 'center' }}>{ !["null", "None", null].includes(this.props.agent_matrix.agent_rapo_with_customer.score ) ? <GaugeCharts value={(this.props.agent_matrix.agent_rapo_with_customer.score/this.props.agent_matrix.agent_rapo_with_customer.total_score)} /> : <div className="circle" style={{ marginLeft: '23px' }}>NA</div> }<p className="title-css" data-title={this.props.agent_matrix.agent_rapo_with_customer.action_took ? this.props.agent_matrix.agent_rapo_with_customer.action_took : this.props.agent_matrix.agent_rapo_with_customer.action_took}>Rapo</p></div>
          </Col> 
          <Col lg="2">
            <div className="d-flex" style={{ flexDirection: 'column', alignItems: 'center' }}>{ !["null", "None", null].includes(this.props.agent_matrix.agent_answered_customer_questions.score ) ? <GaugeCharts value={(this.props.agent_matrix.agent_answered_customer_questions.score/this.props.agent_matrix.agent_answered_customer_questions.total_score)} /> : <div className="circle" style={{ marginLeft: '23px', marginTop: '17px' }}>NA</div> }<p className="title-css" data-title={this.props.agent_matrix.agent_answered_customer_questions.action_took ? this.props.agent_matrix.agent_answered_customer_questions.action_took : this.props.agent_matrix.agent_answered_customer_questions.action_took} style={{ marginLeft:'27px' }}>Answering Questions </p></div>
          </Col>    

        </div>

        <div className="agent-matrix">
          <Col lg="2">
            <div className="d-flex" style={{ flexDirection: 'column', alignItems: 'center' }}>{ !["null", "None", null].includes(this.props.agent_matrix.tone_of_agent.score ) ? <GaugeCharts value={(this.props.agent_matrix.tone_of_agent.score/this.props.agent_matrix.tone_of_agent.total_score)} /> : <div className="circle" style={{ marginLeft: '23px' }}>NA</div> }<p className="title-css" data-title={this.props.agent_matrix.tone_of_agent.action_took ? this.props.agent_matrix.tone_of_agent.action_took : this.props.agent_matrix.tone_of_agent.action_took}>Tone</p></div>
          </Col> 
          <Col lg="2">
            <div className="d-flex" style={{ flexDirection: 'column', alignItems: 'center' }}>{ !["null", "None", null].includes(this.props.agent_matrix.follow_up.score ) ? <GaugeCharts value={(this.props.agent_matrix.follow_up.score/this.props.agent_matrix.follow_up.total_score)} /> : <div className="circle" style={{ marginLeft: '23px' }}>NA</div> }<p className="title-css" data-title={this.props.agent_matrix.follow_up.action_took ? this.props.agent_matrix.follow_up.action_took : this.props.agent_matrix.follow_up.action_took}>Follow-up</p></div>
          </Col> 
          <Col lg="4">
            <p className="customerMatrix">{ this.props.agent_matrix.agent_hold.flag=="Yes" ?  <span className="d-flex" data-title={this.props.agent_matrix.agent_hold.reason_for_yes}><span className="wrong-mark" >✖</span>&nbsp;Hold</span> : <span className="d-flex" data-title={this.props.agent_matrix.agent_hold.reason_for_no}><span className="right-mark" >✔</span>&nbsp;No Hold</span> }</p>
            <p className="customerMatrix">{ this.props.agent_matrix.gaps_in_call_by_agent.flag=="Yes" ?  <span className="d-flex" data-title={this.props.agent_matrix.gaps_in_call_by_agent.reason_for_yes}><span className="wrong-mark" >✖</span>&nbsp;Gaps</span> : <span className="d-flex" data-title={this.props.agent_matrix.gaps_in_call_by_agent.reason_for_no}><span className="right-mark" >✔</span>&nbsp;No Gaps</span> }</p>       
          </Col>
          <Col lg="4">
            <p className="customerMatrix">{ this.props.agent_matrix.agent_background_noise.flag=="Yes" ?  <span className="d-flex" data-title={this.props.agent_matrix.agent_background_noise.reason_for_yes}><span className="wrong-mark" >✖</span>&nbsp;Background noise</span> : <span className="d-flex" data-title={this.props.agent_matrix.agent_background_noise.reason_for_no}><span className="right-mark" >✔</span>&nbsp;No Background noise</span> }</p>
            <p className="customerMatrix">{ this.props.agent_matrix.intruption_by_agent.flag=="Yes" ?  <span className="d-flex" data-title={this.props.agent_matrix.intruption_by_agent.reason_for_yes} ><span className="wrong-mark" >✖</span>&nbsp;Interruption</span> : <span className="d-flex" data-title={this.props.agent_matrix.intruption_by_agent.reason_for_no} ><span className="right-mark" >✔</span>&nbsp;No Interruption</span> }</p>
             
          </Col>
        </div>

        </Row>
        
    );
  }
}

export default AgentMatrix;
