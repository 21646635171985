import React, { Component } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import google_gemini_icon from '../../images/google-gemini-icon.webp';
import { GrFormPreviousLink, GrFormNextLink } from "react-icons/gr";
import { RxCross2 } from "react-icons/rx";



class Popup extends Component {
  formatKey = (key) => {
     return key
       .split("_") // Split by '_'
       .filter((word) => word.toLowerCase() !== "agent") // Remove 'agent'
       .map((word, index, arr) => {
         // Capitalize each word
         let formattedWord = word.charAt(0).toUpperCase() + word.slice(1);
         // Singularize the last word if it ends with 's'
         if (index === arr.length - 1 && formattedWord.endsWith("s")) {
           formattedWord = formattedWord.slice(0, -1);
         }
         return formattedWord;
       })
       .join(" "); // Join words with spaces
   };
  render() {
    const { agent_matrix } = this.props; // Access the agent matrix from props
    return (
      <div style={styles.overlay}>
        <div style={styles.popup}>
          <div className="d-flex justify-content-between">
            <h2>Call Feedback</h2>
            <RxCross2 onClick={this.props.closePopup} style={{ cursor:'pointer' }}/>
          </div>
          <div className="f-summary" style={{ overflowX: 'hidden' }}>
            {this.props.agent_matrix && (
            <>
              <div>
                <h6>Overall</h6>
                <div className="d-flex row">
                  <div className="col-lg-6 actiontook">
                    <h6>Action Took</h6>
                    <p>{agent_matrix.action_took || 'NA'}</p>
                  </div>
                  <div className="col-lg-6 actionrequired">
                    <h6>Action Required</h6>
                    <p>{agent_matrix.action_required || 'NA'}</p>
                  </div>
                </div>
              </div>
              <div>
                <h6 style={{ marginTop: '0.5rem' }}>Pitching</h6>
                <div className="d-flex row">
                  <div className="col-lg-6 actiontook">
                    <h6>Action Took</h6>
                    <p>{agent_matrix.agent_pitch_quality.action_took || 'NA'}</p>
                  </div>
                  <div className="col-lg-6 actionrequired">
                    <h6>Action Required</h6>
                    <p>{agent_matrix.agent_pitch_quality.action_required || 'NA'}</p>
                  </div>
                </div>
              </div>
              <div>
                <h6 style={{ marginTop: '0.5rem' }}>Probing Questions</h6>
                <div className="d-flex row">
                  <div className="col-lg-6 actiontook" >
                    <h6>Action Took</h6>
                    <p>{agent_matrix.probing_questions_skill.action_took || 'NA'}</p>
                  </div>
                  <div className="col-lg-6 actionrequired">
                    <h6>Action Required</h6>
                    <p>{agent_matrix.probing_questions_skill.action_required || 'NA'}</p>
                  </div>
                </div>
              </div>
              <div>
                <h6 style={{ marginTop: '0.5rem' }}>Greetings</h6>
                <div className="d-flex row">
                  <div className="col-lg-6 actiontook">
                    <h6>Action Took</h6>
                    <p>{agent_matrix.agent_greetings_at_start_and_end.action_took || 'NA'}</p>
                  </div>
                  <div className="col-lg-6 actionrequired">
                    <h6>Action Required</h6>
                    <p>{agent_matrix.agent_greetings_at_start_and_end.action_required || 'NA'}</p>
                  </div>
                </div>
              </div>
              <div>
                <h6 style={{ marginTop: '0.5rem' }}>Rapo</h6>
                <div className="d-flex row">
                  <div className="col-lg-6 actiontook">
                    <h6>Action Took</h6>
                    <p>{agent_matrix.agent_rapo_with_customer.action_took || 'NA'}</p>
                  </div>
                  <div className="col-lg-6 actionrequired">
                    <h6>Action Required</h6>
                    <p>{agent_matrix.agent_rapo_with_customer.action_required || 'NA'}</p>
                  </div>
                </div>
              </div>
              <div>
                <h6 style={{ marginTop: '0.5rem' }}>Answering Questions</h6>
                <div className="d-flex row">
                  <div className="col-lg-6 actiontook">
                    <h6>Action Took</h6>
                    <p>{agent_matrix.agent_answered_customer_questions.action_took || 'NA'}</p>
                  </div>
                  <div className="col-lg-6 actionrequired">
                    <h6>Action Required</h6>
                    <p>{agent_matrix.agent_answered_customer_questions.action_required || 'NA'}</p>
                  </div>
                </div>
              </div>
              <div>
                <h6 style={{ marginTop: '0.5rem' }}>Tone</h6>
                <div className="d-flex row">
                  <div className="col-lg-6 actiontook">
                    <h6>Action Took</h6>
                    <p>{agent_matrix.tone_of_agent.action_took || 'NA'}</p>
                  </div>
                  <div className="col-lg-6 actionrequired">
                    <h6>Action Required</h6>
                    <p>{agent_matrix.tone_of_agent.action_required || 'NA'}</p>
                  </div>
                </div>
              </div>
              <div>
                <h6 style={{ marginTop: '0.5rem' }}>Follow-up</h6>
                <div className="d-flex row">
                  <div className="col-lg-6 actiontook">
                    <h6>Action Took</h6>
                    <p>{agent_matrix.follow_up.action_took || 'NA'}</p>
                  </div>
                  <div className="col-lg-6 actionrequired">
                    <h6>Action Required</h6>
                    <p>{agent_matrix.follow_up.action_required || 'NA'}</p>
                  </div>
                </div>
              </div>
            </>
            )}
          </div>
        </div>
      </div>
    );
  }
}


class FurtherAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      score: 78,
      showPopup: false,
    };
  }

  // Function to determine the stroke color based on the value
  getStrokeColor(value) {
    if (value <= 25) {
      return 'red';
    } else if (value <= 80) {
      return '#F08000'; // Orange color
    } else if (value <= 100) {
      return '#009900'; // Green color
    }
  }

  openPopup = () => {
    this.setState({ showPopup: true });
  };

  closePopup = () => {
    this.setState({ showPopup: false });
  };

  sendInputMessage = () => {
    window.handleFurtherActionClick("What is further action to be done as agent to this call?");
  };

  render() {
    const { agent_matrix } = this.props; // Access the agent matrix from props
    const value = agent_matrix.overall_score_of_agent; // Assuming this is passed in props
    const strokeColor = this.getStrokeColor(value); // Get the stroke color based on the score

    return (
      <div>
        {this.state.showPopup && (
          <Popup agent_matrix={this.props.agent_matrix} closePopup={this.closePopup} />
        )}
          <div className="white-bg" style={{ height: '100%', width: '100%', marginLeft: '2px' }}>
            <div className="d-flex" style={{ flexDirection: 'Column', padding: '10px' }}>
              <div>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex">
                  <img
                    className="gemini-icon"
                    src={google_gemini_icon}
                    alt="Gemini Icon"
                    onClick={this.sendInputMessage}
                  />
                  <h6 className="Agent-heading" style={{ paddingLeft: '11px' }}>
                    Further Action
                  </h6>
                  </div>
                </div>
                <p style={{ paddingLeft: '30px' }}>{'further_action' in this.props.call_key_matrix ? this.props.call_key_matrix.further_action.summary : 'NA'}</p>

                {/* Need more help? Button moved to bottom */}
              </div>
              <div className="d-flex justify-content-center" style={{ marginTop: 'auto', paddingBottom: '10px', paddingLeft: '15px' }}>
                <div>
                Need more help?{' '}
                <button className="button-gemini" onClick={() => window.toggleModal_true()} style={{ marginLeft: '5px' }}>
                  Ask CalliQ
                </button>
                </div>

                <div style={{ marginLeft: '5px' }}>
                <button className="button-gemini" onClick={this.openPopup}>
                  Call Feedback
                </button>
                </div>
              </div>

            </div>
          </div>
      </div>
    );
  }
}


const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1
  },
  popup: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '5px',
    width: '60%',
    height: '85%',
    textAlign: 'center',
  }
};

export default FurtherAction;
