import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import NavComponent from './Pages/NavComponent';
import Cookies from "universal-cookie";
const cookies = new Cookies();

const PrivateRoute = ({ isAllowed = true, redirectTo = "/login", children }) => {
  if (!isAllowed || !cookies.get('calliq_token')) {
    return <Navigate to={redirectTo} />;
  }

  return children ? <NavComponent>{children}</NavComponent> : <Outlet />;
};

export default PrivateRoute;
