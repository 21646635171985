import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class TopDiscussedTopics extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };

  }
  render() {
    return (
          <Row className="white-bg p-3 common-class" style={{  height:'50%' }}>
            <h6 >Top Discussed Topics</h6>

            <ul className="top-lists">
              {this.props.top_discussed_topics.length !== 0 ? this.props.top_discussed_topics.map((each_topic, index) => (
                  <li className="topics-list" key={ each_topic }>
                      <span> { each_topic }</span>
                  </li>
              )) : <li className="topics-list">No Top Discussed phrases</li>}
            </ul>
        </Row>
    );
  }
}

export default TopDiscussedTopics;
