import  { Component } from 'react'
import { API_HOST } from '../../settings.js'
import google_icon from '../../images/google-icon.svg'
import './login_page.css'
import {firebase_auth, google_provider, firebaseSignInWithPopup} from '../MyFirebase.js'
import Cookies from "universal-cookie";
import history from '../../history'
import $ from 'jquery'
import axios from 'axios';

const cookies = new Cookies();

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  componentDidMount(){
    $('#loader-wrapper').fadeOut('slow')
  }

  login_google = async (event) => {
    event.preventDefault();
    $('#loader-wrapper').fadeIn()
    var is_popup_success = false
    var is_token_success = false
    var user_display_name = ''
    var user_email = ''
    try{
      var popup_res = await firebaseSignInWithPopup(firebase_auth, google_provider)
      user_display_name = popup_res.user.displayName
      user_email = popup_res.user.email
      is_popup_success = true
    }catch(error_popup){
      var handled = true
      if(error_popup.code){
        if(error_popup.code=='auth/user-disabled'){
          alert(error_popup.message)
        }else if(error_popup.code=='auth/network-request-failed'){
          alert("Network error, Please check your internet connection and retry")
        }else if(error_popup.code=='auth/too-many-requests'){
          alert('Too many requests, Please retry after sometime')
        }else if(error_popup.code=='auth/user-token-expired'){
          alert('Token expired, Please re-login')
        }else if(error_popup.code=='auth/web-storage-unsupported'){
          alert('Allow the Third-Party cookies and then retry')
        }else if(error_popup.code=='auth/admin-restricted-operation'){
          alert("You are not allowed to login")
        }else{
          handled = false
        }
      }else{
        handled = false
      }
      if(!handled){
        console.log('Error during popup result handling:', error_popup);
      }
      $('#loader-wrapper').fadeOut('slow')
    }
    if(is_popup_success){
      try{
        var res_idtoken = await popup_res.user.getIdToken() 
        is_token_success = true
      }catch(error_token){
        is_token_success = false
      }
    }
    if(is_token_success){
      try{
        const res_access_token = await axios.post(`${API_HOST}/users/get/access/token/`,
          {"id_token": res_idtoken})
        var access_token = res_access_token.data.access_token
        cookies.set('calliq_token', access_token, {path:'/'})
        cookies.set('user_email', user_email, {path:'/'})
        localStorage.setItem('calliq_display_name', user_display_name)
        $('#loader-wrapper').fadeOut('slow')
        window.location.href = '/?call-type=inbound'
      }catch(error_access_token){
      }
    }
  }

  render(){
    return <div className="Login-main-container"> 
        <div className="p-3 mt-3" id="sign-in-card">
          <div id="loader-wrapper">
            <div id="loader"></div>
            <div className="loader-section section-left"></div>
            <div className="loader-section section-right"></div>
          </div>
          <div className="d-flex justify-content-between">
          <img
              src="https://www.briotech.com/wp-content/uploads/2022/05/brio-black-1.svg"
              height="40px"
              className="d-inline-block align-top"
              alt="Brio Technologies Private Limited" />
           <div className="text-center">
              <h2 >CalliQ</h2>
          </div>
          </div>
          <div className="d-flex justify-content-center">
            <div className="social-buttons">
              <button className="google social-btn-height" onClick={this.login_google}>
                <img className="social-img" src={google_icon}/>
                <span className="textstyle">Sign in with Google</span>
              </button>
            </div>
          </div>
        </div>
    </div>
  }
}

export default LoginPage